<script>
export default {
	data() {
		return {
			date: '20240620',
			version:'V1.0.2'
		}
	}
}
</script>
<template>
	<!-- BEGIN #footer -->
	<div id="footer" class="app-footer" style="font-size: 0.5rem; text-align: end; padding: 0px; padding-right: 1rem;">
		&copy; {{ date }}-{{ version }} All Right Reserved
	</div>
	<!-- END #footer -->
</template>
