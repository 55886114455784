<template>
  <div>
    <div>APPLE</div>
  </div>
</template>

<script setup>
// import Axios, { AuthApi } from '@/api';
// import { requestKakaoToken } from '@/common/auth/kakao';
import { onBeforeMount } from '@vue/runtime-core';

onBeforeMount(() => {
  document.addEventListener('AppleIDSignInOnSuccess', (data) => {
    console.log(data?.detail?.authorization);
  });

  document.addEventListener('AppleIDSignInOnFailure', (error) => {
    console.error(error);
  });
});

// https://developer111.tistory.com/57 apple 로그인 참고
// https://kedric-me.tistory.com/entry/Apple%EB%A1%9C-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EA%B5%AC%ED%98%84web-and-other-platforms
</script>
