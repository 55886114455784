<template>
  <PopDialog
    title="방 정보 수정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <input
      type="text"
      class="form-control form-control-lg fs-15px"
      placeholder="방 이름을 입력해주세요."
      v-model="modifiedRoomName"
    />
  </PopDialog>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import PopDialog from '@/common/components/popup/PopDialog.vue';

const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'roomName',
]);
let modifiedRoomName = ref(props.roomName);

const onOkClick = () => {
  if (props.okCallback) {
    props.okCallback(modifiedRoomName.value);
  }
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};
</script>
