<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">InShow IoT 회원탈퇴</h4>
    <p>
      
      이사 혹은 시스템 철거로 인해 홈네트워크 시스템을 사용할 수 없을 때 진행하시길 바랍니다.<br><br>
     
      공유 회원이 있는 경우 공유 연결된 계정의 연결을 해제한 후 탈퇴 할 수 있습니다.<br><br>

      <code style="font-size: 100%"> ※ 회원 탈퇴시 기존 데이터는 완전 삭제가 되고 복구할 수 없기 때문에 신중히 진행해주시기 바랍니다.</code>       
      
    </p>

    <div class="row">
      <div class="col-xl-6">
        <div class="list-group mb-3">
          <button class="btn btn-primary flex-fill mt-2" @click="onDeleteMemberClick">회원탈퇴</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Axios, { AuthApi } from '@/api';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const getters = computed(() => store.getters);
const profile = computed(() => getters.value.account);
const router = useRouter();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;

// onBeforeMount(() => {
//   if (!getters.value.isLogin) {
//     router.push({ name: 'login', query: { redirectUrl: "/delete/user" }, replace: true });
//   }
// });

const allShareCancel = async (members) => {
  try {
    members.forEach(async member => {
      await requestCancelShare(member._id);
    });
  } catch(e) {
    emitter.emit('showPopAlert', {
      description: "탈퇴에 실패했습니다.",
    });
    return;
  }
  requestDeleteAccount(getters.value.account._id);
};

const deleteMember = async () => {
  let sharedMembers = [];
  try {
    sharedMembers = await requestMembers();
  } catch(e) {
    emitter.emit('showPopAlert', {
      description: e,
    });
    return;
  }

  if (sharedMembers.length > 0) {
    emitter.emit('showPopConfirm', {
      description: "공유된 사용자가 있습니다.\n모든 공유를 취소하고 탈퇴할 수 있습니다.\n공유 페이지로 이동하시겠습니까?",
      okCallback: () => router.push("/share")
    });
  } else {
    requestDeleteAccount(getters.value.account._id);
  }
};

const onDeleteMemberClick = () => {

  if (!getters.value.isLogin) {
    emitter.emit('showPopAlert', {
      description: "로그인 후 이용할 수 있습니다.\n로그인 페이지로 이동합니다.",
      okCallback: () => {
        router.push({ name: 'login', query: { redirectUrl: "/delete/user" }, replace: true });            
      }
    });
  } else {
    emitter.emit('showPopConfirm', {
      description: "정말 탈퇴하시겠습니까?",
      okCallback: deleteMember
    });
  }
};

const requestMembers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.get(AuthApi.MEMBERS, undefined, 'auth');
      if (result.resultCode === '0000') {
        resolve(result.resultData);
      } else {
        reject(result.resultMessage);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const requestDeleteAccount = async (id) => {
  try {
    const result = await Axios.delete(AuthApi.DELETE.replace(":id", id), undefined, 'auth');
    if (result.resultCode === '0000') {
      console.log(result);

      const logoutFunc = () => {
        emitter.on('onLogoutResult', () => {
          emitter.off('onLogoutResult');
        });
        mobile.logout(JSON.stringify({ callback: 'onLogoutResult' }));

        store.dispatch('logout');
        router.push('/login');
      }

      emitter.emit('showPopAlert', {
        description: "회원탈퇴 되었습니다.",
        okCallback: logoutFunc,
        cancelCallback: logoutFunc
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '회원 탈퇴에 실패했습니다.',
    });
  }
};

const requestCancelShare = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.post(AuthApi.CANCEL_SHARE, { id }, 'auth');
      if (result.resultCode === '0000') {
        resolve(result);
      } else {
        reject(result.resultMessage);
      }
    } catch (error) {
      reject(error);
    }
  });
};
</script>
