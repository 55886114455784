<template>
  <div class="col" style="padding-left: 2px;">
    <div class="list-group" style="border-radius: 1.2rem;" @click="onVentilatorPowerClicked">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background-color: ${deviceState(4) ? '#ffecb3' : 'inherits'}`">
        <i class="fa-solid fa-fan me-2" />
        <div class="flex-fill" style="font-size: 0.8rem">
          {{ props.roomName }} 환풍기
        </div>
        <div class="mx-1" v-if="ventilatorDevices.length > 0" @click.stop="onExpandClicked">
          <i :class="`fa ${isExpand ? 'fa-chevron-up' : 'fa-chevron-down'}`" style="font-size: 0.8rem" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isExpand">
        <RoomVentilatorDeviceItem v-for="device in ventilatorDevices" :key="device._id" :roomName="props.roomName" :roomId="props.roomId" :deviceId="device._id" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import RoomVentilatorDeviceItem from './RoomVentilatorDeviceItem.vue';

const { emit } = getCurrentInstance();
const props = defineProps(['roomId', 'roomName']);
const store = useStore();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const isExpand = ref(false);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});

const ventilatorDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 4,
  );
});

const getVentilatorPower = computed(() => {
  return ventilatorDevices.value.some((d) => d.item?.onoff === PowerState.On);
});

const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.onoff === PowerState.On);
};

const onVentilatorPowerClicked = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  emit('onPowerClicked', {
    id: props.roomId,
    type: 4,
    onoff: getVentilatorPower.value ? PowerState.Off : PowerState.On,
  });
};

const onExpandClicked = (e) => {
  isExpand.value = !isExpand.value;
}
</script>