import { Toast } from 'bootstrap';

const toast = {
  namespaced: true,
  state: {
    title: '알림',
    description: '',
    type: '',
  },
  getters: {
    title: (state) => state.title,
    description: (state) => state.description,
    type: (state) => state.type,
  },
  mutations: {
    setTitle: (state, payload) => (state.title = payload),
    setDescription: (state, payload) => (state.description = payload),
    setType: (state, payload) => (state.type = payload),
    init: (state) => {
      state.title = '알림';
      state.description = '';
      state.type = '';
    },
  },
  actions: {
    show: (store, payload) => {
      console.log(payload?.description?.length);
      if (payload?.title?.length > 0) {
        store.commit('setTitle', payload?.title);
      }
      if (payload?.description?.length > 0) {
        store.commit('setDescription', payload?.description);
      }
      if (payload?.type?.length > 0) {
        store.commit('setType', payload?.type);
      }

      const toastElem = document.getElementById('pop-toast');
      var toast = new Toast(toastElem);
      toastElem.addEventListener('hidden.bs.toast', () => {
        store.commit('init');
      });
      toast.show();
    },
  },
};

export default toast;
