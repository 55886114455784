<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">

        <h2 class="title mt-4 ml-4">
          {{device.name}}
          <button class="btn dropdown-toggle" type="button" id="menus" data-bs-toggle="dropdown" aria-expanded="true" ref="menusButton" />
          <ul class="dropdown-menu" aria-labelledby="menus">
            <li class="dropdown-item" @click="onChangeNameClick">이름 변경</li>
            <li class="dropdown-item" @click="onDeleteClick">삭제</li>
          </ul>
        </h2>
        <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${getPower ? buttonColor : 'inherits'}`"
          @click="props.handlePowerClick"
        >
          <i class="fa-solid fa-power-off"></i>
        </button>

        <div style="margin-right: 20px;" v-if="!device.isVirtual">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              name="밝기"
              id="color-bright"
              :min="10"
              :max="100"
              :step="10"
              :default="lightBright"
              v-on:onSliderChanged="onBrightChanged"
            />
          </ListGroupItem>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              name="색온도"
              id="color-temp"
              :min="0"
              :max="20"
              :step="2"
              :default="lightColor"
              v-on:onSliderChanged="onColorChanged"
            />
          </ListGroupItem>
        </div>

        <div style="margin-left: 20px;margin-right: 20px; margin-top: 10px;" v-else>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <button
              type="button"
              class="btn btn-primary flex-fill"
              @click="onUpdateVirtualClick"
            >
              가상버튼 수정
            </button>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </PopDialog2>
  <DeviceItemModal
    v-if="deviceItemModal.visible"
    :visible="deviceItemModal.visible"
    :okCallback="onUpdateDeviceName"
    :cancelCallback="deviceItemModal.hide"
    :deviceName="device.name || ''"
    :groupId="props.roomId"
  />
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DeviceType, PowerState, lightButtonColors } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import useModal from '@/common/hooks/useModal';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import DeviceItemModal from './DeviceItemModal.vue';
import Axios, { DeviceApi } from '@/api';

const router = useRouter();
const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const props = defineProps([
  'visible',
  'cancelCallback',
  'roomName',
  'roomId',
  'deviceId',
  'handlePowerClick'
]);
const deviceItemModal = reactive(useModal());
const menusButton = ref(null);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const device = computed(() => {
  return devices.value.find(
    (d) => d._id === props.deviceId,
  );
});

const getPower = computed(() => {
  return device.value.item?.onoff === PowerState.On;
});

const lightBright = computed(() => {
  return device.value.item?.bright;
});

const lightColor = computed(() => {
  return device.value.item?.color;
});

const buttonColor = computed(() => {
  const index = device.value.item?.color / 2;
  return device.value.item?.color !== undefined && lightButtonColors.length > index ? lightButtonColors[index] : "#ffe082";
});

// watch(lightBright, () => {
//   console.log(lightBright.value)
// })

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onBrightChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: device.value.item?.ports?.map((p) => p),
      onoff: device.value.item?.onoff,
      bright: parseInt(e),
      color: device.value.item?.color,
    },
  });
};

const onColorChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: device.value.item?.ports?.map((p) => p),
      onoff: device.value.item?.onoff,
      bright: device.value.item?.bright,
      color: parseInt(e),
    },
  });
};

const onUpdateVirtualClick = () => {
  router.push(`/virtual/${group.value._id}/${device.value._id}`);
}

const onChangeNameClick = () => {
  menusButton.value.click();
  deviceItemModal.show();
}

const onDeleteClick = () => {
  menusButton.value.click();
  if (device.value?.isVirtual && device.value?.item?.onoff == 1) {
    emitter.emit('showPopAlert', {
      description: 'ON 상태의 가상버튼은 삭제할 수 없습니다.',
    });
    return;
  }

  const virtualDevices = devices.value?.filter(device => device.isVirtual);
  if (!device.value?.isVirtual && virtualDevices.length > 0) {
    emitter.emit('showPopAlert', {
      description: '가상버튼이 있으면 삭제할 수 없습니다.',
    });
    return;
  }

  emitter.emit('showPopConfirm', {
    title: '장치 삭제',
    description: `${device.value.name} 장치를 삭제하시겠습니까?`,
    okText: '삭제',
    cancelText: '취소',
    okCallback: () => {
      requestDeleteDevice(props.deviceId);
    },
  });
}

const onUpdateDeviceName = (value) => {
  requestUpdateDevice({
    name: value,
  });
  deviceItemModal.hide();
}

const requestUpdateDevice = async (params) => {
  try {
    const result = await Axios.patch(
      DeviceApi.UPDATE_DEVICE.replace(':id', props.deviceId),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateDevice', {
        groupId: props.roomId,
        id: props.deviceId,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '장치 정보 수정에 실패했습니다.',
    });
  }
};

const requestDeleteDevice = async (deviceId) => {
  try {
    const result = await Axios.delete(
      DeviceApi.DELETE_DEVICE.replace(':id', deviceId),
    );
    if (result.resultCode === '0000') {
      onCancelClick();
      store.commit('home/deleteDevice', {
        zoneId: parseInt(zone.value._id),
        groupId: parseInt(props.roomId),
        deviceId,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    console.log(error)
    emitter.emit('showPopAlert', {
      description: '장치 삭제 중 오류가 발생하였습니다.',
    });
  }
};
</script>
