<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break">
          <div style="font-size: 1rem">{{ props.data.model }}</div>
        </div>
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.3rem; padding-bottom: 0.3rem; padding-right: 0rem;"
      >
        <div class="row" style="width: 100%;">
          <div class="col-9">
            시리얼 넘버 : {{ props.data._id }}<br>
            현재 펌웨어 버전 : {{ props.data.version }}<br>
            최종 펌웨어 버전 : 
            <span v-if="props.data.lastVersion > props.data.version" style="color: #ff0000;">{{ props.data.lastVersion }}</span>
            <span v-else>{{ props.data.lastVersion }}</span>
          </div>
          <div class="col-3">
            <button
              v-if="checkStatus === 1"
              type="button"
              class="btn btn-outline-primary"
              style="width: 100%; height: 100%; margin: 0px; padding: 0px;"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
            <button
              v-else-if="checkStatus === 2"
              type="button"
              class="btn btn-primary"
              style="width: 100%; height: 100%; margin: 0px; padding: 0px;"
              @click="onControllerCheck"
            >
              온라인
            </button>
            <button
              v-else-if="checkStatus === 3"
              type="button"
              class="btn btn-secondary"
              style="width: 100%; height: 100%; margin: 0px; padding: 0px;"
              @click="onControllerCheck"
            >
              오프라인
            </button>
            <button
              v-else
              type="button"
              class="btn btn-outline-primary"
              style="width: 100%; height: 100%; margin: 0px; padding: 0px;"
              @click="onControllerCheck"
            >
              상태확인
            </button>
          </div>
        </div>
      </ListGroupItem>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, computed, getCurrentInstance, watch } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useMqttControl from '@/common/hooks/useMqttControl';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['index', 'data']);
const store = useStore();
const getters = computed(() => store.getters);
const isReceivedCheckStatus = computed(() => {
  const controllers = getters.value['home/getCheckControllers'];
  if (controllers[props.data._id]) {
    return controllers[props.data._id];
  }
  return 0;
});
const checkStatus = ref(0);
const timerId = ref(undefined);

watch(isReceivedCheckStatus, () => {
  if (checkStatus.value === 1 && isReceivedCheckStatus.value === 1) {
    checkStatus.value = 2;
    if (timerId.value) {
      clearTimeout(timerId.value);
      timerId.value = undefined;
    }
  }
});

const onControllerCheck = () => {
  store.dispatch("home/updateCheckControllers", { serial: props.data._id, value: 0 });
  mqttControl.onPublishFirmware(props.data._id, {
    serial: props.data._id,
    cmd: 'p',
    type: 1,
    data: {
      ports: [1],
      requestId: 1
    }
  });

  checkStatus.value = 1;

  timerId.value = setTimeout(() => {
    checkStatus.value = 3;
  }, 3000);
}
</script>
