const DeviceType = {
  Lamp: 1,
  Boiler: 2,
  Curtain: 3,
  Ventilator: 4,
};

const MQTTCommand = {
  StateChanged: 'd',
  SettingMode: 's',
  Control: 'c',
  Version: 'v',
  Ping: 'p',

  /**
   * //펌웨어 업데이트 요청
   * {
        "serial":	"M01_30c6f7f58cf0",
        "cmd":	"f",
        "type":	1,
        "new_version":	10105,
        "firmware_url": "http://aaaaa"
      }
   */
  FirmwareUpdateRequest: 'f',

  /**
   * //펍웨어 업데이트 시작됨
   * {
        "serial":	"M01_30c6f7f58cf0",
        "cmd":	"fs",
        "type":	1,
        "new_version":	10105,
      }
   */
  FirmwareUpdateStarted: 'fs',

  /**
   * //펌웨어 업데이트 종료됨 
   * {
        "serial":	"M01_30c6f7f58cf0",
        "cmd":	"fe",
        "type":	1,
        "new_version":	10105,
      }
   */
  FirmwareUpdateFinished: 'fe',
};

const SettingMode = {
  InSettingMode: 1,
  OutSettingMode: 0,
};

const DeviceDefault = {
  AP_Host: 'http://192.168.4.1',
  AP_SSID: 'inshowled',
  AP_PWD: 'inshow22',
};

const PowerState = {
  On: 1,
  Off: 0,
};

const Template = {
  ZoneNames: ['우리집', '홈', '집', '직접 입력'],
  GroupNames: [
    '거실',
    '안방',
    '방',
    '주방',
    '현관',
    '화장실',
    '베란다',
    '테라스',
    '직접 입력',
  ],
};

const FadeList = [
  {
    value: 0,
    text: "없음",
  },
  {
    value: 1,
    text: "빠름"
  },
  {
    value: 2,
    text: "보통"
  },
  {
    value: 3,
    text: "느림"
  },
];

const SettingConfig = {
  requestCount: 10,
  requestInterval: 5000
};

const ButtonConfig = {
  delay: 1000
};

const lightButtonColors = [
  "#f4d110",
  "#f2d94e",
  "#ffe96f",
  "#fff3af",
  "#fffade",
  "#f0fffc",
  "#dbf2fc",
  "#c4eafb",
  "#b7e5fc",
  "#9ee0fc",
  "#7ad3f9"
];

const lightButtonAnotherColors = [
  "rgba(244, 210, 16, 0.5)",
  "rgba(242, 217, 78, 0.5)",
  "rgba(255, 233, 111, 0.4)",
  "rgba(255, 243, 175, 0.3)",
  "rgba(255, 250, 245, 0.3)",
  "rgba(255, 255, 255, 0.3)",
  "rgba(239, 242, 252, 0.3)",
  "rgba(196, 234, 251, 0.3)",
  "rgba(183, 229, 252, 0.4)",
  "rgba(158, 224, 252, 0.5)",
  "rgba(122, 211, 249, 0.5)",
];

module.exports = {
  DeviceType,
  MQTTCommand,
  SettingMode,
  PowerState,
  DeviceDefault,
  Template,
  FadeList,
  SettingConfig,
  ButtonConfig,
  lightButtonColors,
  lightButtonAnotherColors
};
