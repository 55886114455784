<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break">
          <div style="font-size: 1rem">{{ props.data.model }}</div>
        </div>
        <div @click.stop="onDeleteClick">
          <i class="fa-solid fa-x"></i>
        </div>
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
      >
        시리얼 넘버 : {{ props.data._id }}<br>
        펌웨어 버전 : {{ props.data.version }}
      </ListGroupItem>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, computed, getCurrentInstance } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['index', 'data']);
const store = useStore();
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});

onMounted(() => {
  console.log('ControllerItem.onMounted().props =>', props);
});

// const onDetailClick = () => {
//   console.log('ControllerItem.onDetailClick => ',`/settings/zones/${props.data._id}`)
//   router.push(`/settings/zones/${props.data._id}/groups`);
// };

const onDeleteClick = () => {
  console.log('ControllerItem.onDeleteClick =>', { id: props.data._id });
  emit('onDeleteClick', { id: props.data._id });
};
</script>
