export const checkValidEmail = (email) => {
  var re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const checkValidPw = (pw) => {
  var num = pw.search(/[0-9]/g);
  var eng = pw.search(/[a-z]/gi);
  let special = pw.search(/[!@#$%^*+=-]/g);
  return (
    pw.length >= 8 &&
    pw.search(/\s/) === -1 &&
    num >= 0 &&
    eng >= 0 &&
    special >= 0
  );
};
