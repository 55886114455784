<template>
  <PopDialog
    title="장치 정보 수정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <!-- <input
      type="text"
      class="form-control form-control-lg fs-15px"
      placeholder="장치 이름을 입력해주세요."
      v-model="modifiedDeviceName"
    /> -->

    <div class="flex-fill mb-1">
      <div class="position-relative d-flex align-items-center">
          <div class="menu-search-input flex-fill" style="padding-right: 10px">
            <select class="form-control" v-model="modifiedDeviceName">
              <option value="" style="display: none">장치이름을 선택해주세요.</option>
              <option v-for="index in 99" :key="index" :value="`${index}번`" :disabled="isExistIndex(index)">{{ index }}번</option>
            </select>
          </div>          
      </div>
    </div>
  </PopDialog>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import PopDialog from '@/common/components/popup/PopDialog.vue';

const store = useStore();
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'deviceName',
  'groupId'
]);

let modifiedDeviceName = ref(props.deviceName);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});

const group = computed(() => {
  return zone.value?.groups.find(group => group._id == props.groupId);
});

const devices = computed(() => {
  return group.value?.devices || [];
});

const existIndices = computed(() => {
  const regex = /[^0-9]/g;
  const result = devices.value?.filter((item) => item.name).map((item) => parseInt(item.name.replace(regex, ""))) || [];
  return result;
});

const isExistIndex = (index) => {
  const result = existIndices?.value?.filter(item => item === index) || [];
  return result.length > 0;
};

const onOkClick = () => {
  if (props.okCallback) {
    props.okCallback(modifiedDeviceName.value);
  }
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};
</script>

