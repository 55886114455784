<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">페이드 설정</h4>
      <p class="mb-0">
        1. 원하는 페이드 전환 속도를 <code style="font-size: 100%">체크박스</code>에 체크해주세요.
      </p>
      <p class="mb-0">
        2. <code style="font-size: 100%">변경 버튼</code>을 눌러서 변경해주세요.
      </p>
    </div>

    <hr class="mb-3" v-if="FadeList.length > 0" />

    <div class="row" v-if="FadeList.length > 0">
      <div class="col-xl-6 mb-0">
        <div class="text-muted fs-12px mb-2"><b>페이드 속도 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(fade, index) in FadeList"
            :key="fade.value"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="fade.value"
                :checked="selectedFade == fade.value"
                @change="() => onSelectFadeChange(fade.value, index)"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ fade.text }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onBatchSettingClick"
          >
            변경
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';
import Axios, { DeviceApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import { FadeList } from '@/common/constants';

const store = useStore();
const router = useRouter();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
const mqttControl = reactive(useMqttControl());
const zone = computed(() => store.getters['home/getCurrentZone']);
const selectedFade = ref(2);

onMounted(() => {
  if (zone.value) {
    selectedFade.value = zone.value.fade === undefined ? selectedFade.value : zone.value.fade;
  }
});

onBeforeUnmount(() => {
  store.dispatch('home/fetchAllZones');
});

const onBatchSettingClick = async () => {
  if (selectedFade.value === undefined) {
    emitter.emit('showPopAlert', {
      description: '페이드 속도를 선택해주세요.',
    });
    return;
  }

  try {
    zone.value?.controllers?.forEach(controller => {
      mqttControl.onPublishFade(controller._id, {
        serial: controller._id,
        cmd: 'ds',
        type: 1,
        data: {
          setting: selectedFade.value
        }
      });
    });

    emitter.emit('showPopAlert', {
      description: "페이드 속도가 변경되었습니다.",
      okCallback: () => router.go(-1),
      cancelCallback: () => router.go(-1),
    });
  } catch (e) {
    emitter.emit('showPopAlert', {
      description: "페이드 속도 설정에 실패했습니다.",
    });
  }
};

const onSelectFadeChange = (value, index) => {
  document.getElementById(`gridCheck${index}`).checked = true
  selectedFade.value = value;
};
</script>