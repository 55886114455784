<template>
  <div
    class="list-group-item d-flex align-items-center"
    style="border-top-width: 1px;"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  handleClick: {
    type: Function,
    default: undefined,
  }
});

const onClick = (e) => {
  if (props.handleClick) {
    e.preventDefault();
    e.stopPropagation();
    props.handleClick();
  }
};
</script>
