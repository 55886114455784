<template>
  <button :class="`btn btn-sm ${props.value ? 'btn-primary' : 'btn-default'}`" @click="onClick">
    {{ props.value ? props.onText : props.offText }}
  </button>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance } from 'vue';
const { emit } = getCurrentInstance();
const onoff = ref(false);
const props = defineProps({
  onText: {
    type: String,
    default: '켜짐',
  },
  offText: {
    type: String,
    default: '꺼짐',
  },
  value: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const onClick = () => {
  emit("onValueChange", !props.value);
}
</script>