<template>    
  <div class="list-group" style="border-radius: 1.2rem; margin-top: 1px;" @click="onVentilatorPowerClicked">
    <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background-color: ${getPower ? '#ffecb3' : 'inherits'}`">
      <i class="fa-solid fa-temperature-three-quarters me-2" />
      <div class="flex-fill" style="font-size: 0.8rem">
        {{ device.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const props = defineProps(['roomId', 'roomName', 'deviceId']);
const store = useStore();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const device = computed(() => {
  return devices.value.find(
    (d) => d._id === props.deviceId,
  );
});

const getPower = computed(() => {
  return device.value.item.onoff === PowerState.On;
});

const onVentilatorPowerClicked = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  const data = { 
    serial: device.value.item.serial,    
    onoff: getPower.value ? 0 : 1,
  };

  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: device.value.type,
    data: data,
  });
};
</script>
