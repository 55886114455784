<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">비밀번호 찾기</h1>
      <div class="text-muted text-center mb-4">
        이메일로 비밀번호를 변경할 수 있습니다.
      </div>
      <div class="mb-4">
        <label class="form-label">이메일</label>
        <input
          type="text"
          class="form-control form-control-lg fs-15px"
          :class="
            validCheck
              ? isValidEmail && isValidFormatEmail
                ? ''
                : 'is-invalid'
              : ''
          "
          placeholder="inshow@inshow.com"
          v-model="email"
        />
        <div class="invalid-feedback" v-if="validCheck && !isValidEmail">
          이메일을 입력해주세요.
        </div>
        <div
          class="invalid-feedback"
          v-else-if="validCheck && !isValidFormatEmail"
        >
          유효한 형식의 이메일을 입력해주세요.
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-lg d-block w-100 fw-500 mb-4"
        @click="onFindClick"
      >
        비밀번호 찾기
      </button>
      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-2"
        style="background-color: #fee500"
        @click="onKakaoLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/kakao.svg')"
          style="max-height: 23px; margin-right: 5px"
        />
        카카오 로그인
      </button> -->

      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-2"
        style="background-color: #ffffff"
        @click="onGoogleLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/google.png')"
          style="max-height: 20px; margin-right: 5px"
        />
        Google 로그인
      </button> -->

      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-3"
        style="background-color: #000000; color: #ffffff"
        @click="onAppleLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/apple.png')"
          style="max-height: 22px; margin-right: 6px"
        />
        Apple 로그인
      </button> -->

      <a id="kakao-signin" :href="kakaoAuthUrl" hidden />
      <a id="google-signin" :href="googleAuthUrl" hidden />
      <div id="appleid-signin" data-type="sign in" hidden />

      <div class="text-muted text-center">
        비밀번호를 알고 계신가요?
        <RouterLink to="/login">로그인</RouterLink>
      </div>

      <div class="text-center text-muted mt-1">
        아직 계정이 없으신가요?
        <RouterLink to="/join">회원가입</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';
import Axios, { AuthApi } from '@/api';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;

const email = ref('');
const password = ref('');
const validCheck = ref(false);
const loginInfoCheck = ref(false);
const isValidEmail = computed(() => email.value?.length > 0);
const isValidFormatEmail = computed(() => checkValidEmail(email.value));
const isValidFind = computed(
  () => isValidEmail.value && isValidFormatEmail.value
);

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  hideLayoutWidgets();
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

const requestFindPassword = async (params) => {
  Axios.get(AuthApi.SEND_EMAIL_PASSWORD, params)
    .then((res) => {
      if (res.resultCode == "0000") {
        emitter.emit('showPopAlert', {
          description: '비밀번호를 변경할 수 있는 이메일이 전송되었습니다.\n이메일을 확인해주세요.',
          okCallback: () => router.push("/login"),
          cancelCallback: () => router.push("/login")
        });
      } else {
        console.error(res.resultMessage);
        emitter.emit('showPopAlert', {
          description: res.resultMessage,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      emitter.emit('showPopAlert', {
        description: "비밀번호 찾기에 실패했습니다.",
      });
    });
};

const onFindClick = async () => {
  validCheck.value = true;
  if (isValidFind.value) {
    requestFindPassword({
      email: email.value,
    });
  }
};
</script>
