<template>
  <div hidden />
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(() => {
  const accessToken = sessionStorage.getItem('accessToken');
  const refreshToken = sessionStorage.getItem('refreshToken');

  if (accessToken?.length > 0) {
    store.commit('setAccessToken', accessToken);
    store.dispatch('requestAccount');
    store.dispatch('home/fetchAllZones');
    store.dispatch('home/fetchFirmwares');
    store.dispatch('home/fetchProducts');
  }
  if (refreshToken?.length > 0) {
    store.commit('setRefreshToken', refreshToken);
  }
});
</script>
