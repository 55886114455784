<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">공유</h4>
    <p>
      공유를 원하는 상대방의 공유코드를 아래에 입력해주세요. 공유하기에 성공하면
      입력한 공유코드의 사용자가 나의 설정 정보를 전달 받습니다. 예를들어
      <code style="font-size: 100%"
        >총 관리자 계정에서 일반 사용자 계정의 공유코드를 입력</code
      >합니다.
    </p>
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>공유하기</b></div>
        <Card :class="`mb-3 overflow-hidden fs-13px`" style="min-height: 50px; border-radius: 1.2rem;">
          <CardBody class="position-relative">
            <ListGroupItem>
              <div class="flex-fill" style="margin-right: 10px;">
                <!-- <div class="fw-600">{{ profile?.id || '' }}</div>
              <div class="fs-12px text-muted">공유 코드</div> -->
                <input
                  type="text"
                  class="form-control form-control-lg fs-15px"
                  placeholder="공유코드를 입력해주세요."
                  v-model="shareCode"
                />
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary w-60px"
                  @click="onShareClick"
                >
                  공유
                </button>
              </div>
            </ListGroupItem>
          </CardBody>
        </Card>
      </div>
      <div class="col-xl-6" v-if="sharedMembers.length > 0">
        <div class="text-muted fs-12px mb-2"><b>구성원</b></div>
        <Card>
          <div class="list-group list-group-flush">
            <MemberItem
              v-for="(item, index) in sharedMembers"
              :key="index"
              :item="item"
              :handleSharedZonesUpdated="onSharedZonesChanged"
            />
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import MemberItem from './components/MemberItem.vue';
import { ref, getCurrentInstance, onBeforeMount ,onBeforeUnmount} from 'vue';
import Axios, { AuthApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const shareCode = ref('');
let sharedMembers = ref([]);

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting',{title:'공유',isShowZone:false});  
  requestMembers();
});
onBeforeUnmount(() =>{
  emitter.emit('onChangeHeaderSetting',{title:'',isShowZone:true});
})


const requestShare = async (params) => {
  try {
    const result = await Axios.post(AuthApi.SHARE, params, 'auth');
    if (result.resultCode === '0000') {
      sharedMembers.value.push(result.resultData);
      shareCode.value = "";
      emitter.emit('showPopAlert', {
        description: '공유가 완료되었습니다.',
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '공유하기에 실패했습니다.',
    });
  }
};

const requestMembers = async () => {
  try {
    const result = await Axios.get(AuthApi.MEMBERS, undefined, 'auth');
    if (result.resultCode === '0000') {
      sharedMembers.value = result.resultData;
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '공유 구성원 조회에 실패했습니다.',
    });
  }
};

const onShareClick = () => {
  requestShare({ id: shareCode.value });
};

const onSharedZonesChanged = (params) => {
  requestMembers();
  // const targetIndex = sharedMembers.value.findIndex(
  //   (item) => item._id === params.id,
  // );
  // if (targetIndex >= 0) {
  //   // sharedMembers.value[targetIndex].zones = params.zones;
  //   requestMembers();
  // }
};
</script>
