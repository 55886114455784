<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">로그인</h1>
      <div class="text-muted text-center mb-4">
        INSHOW앱을 사용하기 위해서 로그인 해주세요.
      </div>
      <div class="mb-3">
        <label class="form-label">이메일</label>
        <input
          type="text"
          class="form-control form-control-lg fs-15px"
          :class="
            validCheck
              ? isValidEmail && isValidFormatEmail
                ? ''
                : 'is-invalid'
              : ''
          "
          placeholder="inshow@inshow.com"
          v-model="email"
        />
        <div class="invalid-feedback" v-if="validCheck && !isValidEmail">
          이메일을 입력해주세요.
        </div>
        <div
          class="invalid-feedback"
          v-else-if="validCheck && !isValidFormatEmail"
        >
          유효한 형식의 이메일을 입력해주세요.
        </div>
      </div>
      <form>
        <div class="mb-3">
          <div class="d-flex">
            <label class="form-label">비밀번호</label>
            <!-- <a href="#" class="ms-auto text-muted">Forgot password?</a> -->
          </div>
          <input
            autoComplete="off"
            type="password"
            class="form-control form-control-lg fs-15px"
            :class="validCheck ? (isValidPassword ? '' : 'is-invalid') : ''"
            placeholder="비밀번호를 입력해주세요."
            v-model="password"
          />
          <div class="invalid-feedback" v-if="validCheck && !isValidPassword">
            비밀번호를 입력해주세요.
          </div>
        </div>
      </form>
      <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="customCheck1"
            v-model="loginInfoCheck"
          />
          <label class="form-check-label fw-500" for="customCheck1"
            >로그인 정보 저장</label
          >
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-lg d-block w-100 fw-500 mb-4"
        @click="onLoginClick"
      >
        로그인
      </button>
      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-2"
        style="background-color: #fee500"
        @click="onKakaoLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/kakao.svg')"
          style="max-height: 23px; margin-right: 5px"
        />
        카카오 로그인
      </button> -->

      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-2"
        style="background-color: #ffffff"
        @click="onGoogleLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/google.png')"
          style="max-height: 20px; margin-right: 5px"
        />
        Google 로그인
      </button> -->

      <!-- <button
        type="submit"
        class="btn btn-lg d-block w-100 fw-500 mb-3"
        style="background-color: #000000; color: #ffffff"
        @click="onAppleLoginClick"
      >
        <img
          :src="require('@/assets/images/sns/apple.png')"
          style="max-height: 22px; margin-right: 6px"
        />
        Apple 로그인
      </button> -->

      <a id="kakao-signin" :href="kakaoAuthUrl" hidden />
      <a id="google-signin" :href="googleAuthUrl" hidden />
      <div id="appleid-signin" data-type="sign in" hidden />

      <div class="text-center text-muted">
        아직 계정이 없으신가요?
        <RouterLink to="/join">회원가입</RouterLink>
      </div>

      <div class="text-center text-muted mt-1">
        비밀번호를 잊으셨나요?
        <RouterLink to="/find/password">비밀번호 찾기</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;

const email = ref('');
const password = ref('');
const validCheck = ref(false);
const loginInfoCheck = ref(false);
const isValidEmail = computed(() => email.value?.length > 0);
const isValidFormatEmail = computed(() => checkValidEmail(email.value));
const isValidPassword = computed(() => password.value?.length > 0);
const isValidLogin = computed(
  () => isValidEmail.value && isValidFormatEmail.value && isValidPassword.value,
);

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  console.log('LoginPage.onBeforeMount => ');
  if (getters.value.isLogin) {
    router.push('/rooms');
  }
  hideLayoutWidgets();
  store.commit('clearTempSnsInfo');

  emitter.on('onGetLoginInfoResult', (payload) => {
    // console.log("onGetLoginInfoResult", JSON.stringify(payload?.data || ""));
    const { email: id, password: pwd, type } = payload?.data || {};
    if (id?.length > 0 && pwd?.length > 0) {
      email.value = id;
      password.value = pwd;
      loginInfoCheck.value = true;

      // requestLogin({
      //   type: type,
      //   email: email.value,
      //   password: password.value,
      // });
    }

    // remove event
    emitter.off('onGetLoginInfoResult');
  });

  mobile.getLoginInfo(JSON.stringify({ callback: 'onGetLoginInfoResult' }));
});

onMounted(() => {
  window.AppleID.auth.init({
    clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
    scope: 'email',
    redirectURI: `${process.env.VUE_APP_PROXY_DOMAIN}/callback/apple`,
    state: 'inshow',
  });

  console.log(process.env.VUE_APP_PROXY_DOMAIN);
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

const setLoginInfo = (params) => {
  emitter.on('onSetLoginInfoResult', (payload) => {
    // if (decoded.message) {
    //   showPopAlert(decoded.message);
    // }

    // remove event
    emitter.off('onSetLoginInfoResult');
  });

  mobile.setLoginInfo(JSON.stringify({ callback: 'onSetLoginInfoResult', ...params }));
}

const requestLogin = async (params) => {
  try {
    const result = await store.dispatch('login', params);
    if (result.resultCode === '0000') {
      if (loginInfoCheck.value) {
        setLoginInfo({
          type: params.type,
          email: params.email,
          password: params.password
        });
      } else {
        setLoginInfo({
          type: "",
          email: "",
          password: ""
        });
      }

      router.push(route.query?.redirectUrl || '/rooms');
      showLayoutWidgets();
    } else {
      emitter.emit('showPopAlert', {
        description: '이메일 또는 비밀번호가 일치하지 않습니다.',
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '로그인에 실패했습니다.',
    });
  }
};

const onLoginClick = async () => {
  validCheck.value = true;
  if (isValidLogin.value) {
    requestLogin({
      type: 'e',
      email: email.value,
      password: password.value,
    });
  }
};

const onKakaoLoginClick = () => {
  document.getElementById('kakao-signin').click();
};

const onGoogleLoginClick = () => {
  document.getElementById('google-signin').click();
};

const onAppleLoginClick = () => {
  document.getElementById('appleid-signin').click();
};
</script>
