import { getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import Axios, { CtrlApi, ZoneApi } from '@/api';
import { DeviceDefault } from '@/common/constants';

export default function useMobile() {
  const { emitter, mobile, logger } =
    getCurrentInstance().appContext.config.globalProperties;
  const store = useStore();
  const getters = computed(() => store.getters);
  const controllers = computed(
    () => getters.value['home/getLowVersionControllers'],
  );

  const ap_wifi = {
    ssid: DeviceDefault.AP_SSID,
    password: DeviceDefault.AP_PWD,
  };

  const getWifiInfo = () => {
    // show loading
    // add event
    return new Promise((resolve, reject) => {
      emitter.on('onGetWifiResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onGetWifiResult');

        logger.log('TransferReceivePage.getWifiInfo => payload : ' + payload);

        let currentWifi = undefined;
        if (payload.data) {
          currentWifi = JSON.parse(
            decodeURIComponent(window.atob(payload.data)),
          );
          logger.log(
            'TransferReceivePage.getWifiInfo => currentWifi : ' +
              JSON.stringify(currentWifi),
          );

          if (!currentWifi) {
            reject('검색 결과가 없습니다.');
            return;
          }

          resolve(currentWifi);
        }
      });

      emitter.emit('showLoading');
      const ret = mobile.getWifiInfo(JSON.stringify({ callback: 'onGetWifiResult' }));
      if (ret === false) {
        emitter.emit('hideLoading');
      }
    });
  };

  // 컨트롤러 연결 :
  const connectWifi = () => {
    // add event
    return new Promise((resolve, reject) => {
      emitter.on('onConnectResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onConnectResult');

        logger.log(
          `TransferReceivePage.connectWifi => onConnectResult : payload : ${JSON.stringify(payload)} `,
        );

        if (payload.result) {
          let decoded = JSON.parse(
            decodeURIComponent(window.atob(payload.data)),
          );
          logger.log(
            `TransferReceivePage.connectWifi => onConnectResult : decoded : ${JSON.stringify(
              decoded,
            )}`,
          );

          if (decoded) {
            if (decoded.message) {
              reject(decoded.message);
              return;
            }
            // connectedController.value = decoded.device
            let arr = decoded.device.serial_no.split('_');
            resolve({
              model: arr[0],
              serial_no: decoded.device.serial_no,
              version: decoded.device.version,
            });
            return;
          }
          return;
        }

        reject('연결에 실패하였습니다.');
      });

      emitter.emit('showLoading');
      mobile.connectWifi(
        JSON.stringify({
          ssid: ap_wifi.ssid,
          password: ap_wifi.password,
          bssid: ap_wifi.bssid || undefined,
          callback: 'onConnectResult',
        }),
      );
    });
  };

  // 컨트롤러 설정 :
  const settingController = (settingParams, controllerId) => {
    return new Promise((resolve, reject) => {
      emitter.on('onSettingResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onSettingResult');

        logger.log(
          `TransferReceivePage.settingController => onSettingResult : payload : ${JSON.stringify(
            payload,
          )}`,
        );
        // if (payload.result) {
          mobile.disConnectWifi(JSON.stringify(ap_wifi));
          resolve();
          return;
        // } else {
        //   let decoded = JSON.parse(
        //     decodeURIComponent(window.atob(payload.data)),
        //   );
        //   logger.log(
        //     `TransferReceivePage.settingController => onSettingResult : decoded : ${JSON.stringify(
        //       decoded,
        //     )} `,
        //   );
        //   if (decoded) {
        //     if (decoded.message) {
        //       reject(decoded.message);
        //       return;
        //     }
        //   }
        //   reject('설정에 실패하였습니다.');
        // }
      });

      let params = { ...settingParams, callback: 'onSettingResult' };
      const targetController = controllers.value.find(
        (item) => item._id === controllerId,
      );
      if (targetController) {
        params = {
          ...params,
          firmware_version: targetController.lastVersion,
          firmware_url: targetController.downloadUrl,
        };
      }

      logger.log(
        `TransferReceivePage.settingController => onSettingResult : params : ${JSON.stringify(
          params,
        )} `,
      );

      emitter.emit('showLoading');
      mobile.settingDevice(JSON.stringify(params));
    });
  };

  // 컨트롤러 검색 :
  const getControllerInfo = (controller) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.getControllerInfo => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        // check controllerId
        const exControllerResult = await Axios.get(
          CtrlApi.CTRL_INFO.replace(':id', controller._id),
        );
        // if (exControllerResult.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.getControllerInfo => exControllerResult : ${JSON.stringify(
              exControllerResult,
            )}`,
          );
          resolve(exControllerResult);
          return;
        // }

        // resolve(undefined);
      } catch (e) {
        console.log(e);
        logger.log(e);
        resolve('컨트롤러 추가중 오류가 발생하였습니다.');
      }
    });
  };

  const disConnectWifi = () => {
    mobile.disConnectWifi(JSON.stringify(ap_wifi));
  }

  // 컨트롤러 이관 :
  const requestTransferController = (controller, userId, srcZoneId = undefined, tarZoneId = undefined) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.requestTransferController => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        const result = await Axios.patch(
          CtrlApi.TRANSFER_CTRL.replace(':id', controller._id), { 
            id: userId,
            srcZoneId: srcZoneId,
            tarZoneId: tarZoneId 
          },
        );
        if (result.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.requestTransferController => result : ${JSON.stringify(
              result,
            )}`,
          );
          resolve(result);
          return;
        }

        // reject(result.resultMessage);
        resolve(result);
      } catch (e) {
        console.log(e);
        logger.log(e);
        // reject('컨트롤러 이관 중 오류가 발생하였습니다.');
        resolve('컨트롤러 이관 중 오류가 발생하였습니다.');
      }
    });
  };

  // 컨트롤러 등록 :
  const requestRegistController = (controller, zoneId) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.requestRegistController => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        const result = await Axios.post(
          ZoneApi.REGIST_ZONE_CTRLS.replace(':id', zoneId),
          controller,
        );
        if (result.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.requestRegistController => result : ${JSON.stringify(
              result,
            )}`,
          );
          resolve(result);
          return;
        }

        // reject(result.resultMessage);
        resolve(result);
      } catch (e) {
        console.log(JSON.stringify(e));
        logger.log(e);
        // reject('컨트롤러 추가중 오류가 발생하였습니다.');
        resolve('컨트롤러 추가중 오류가 발생하였습니다.');
      }
    });
  };

  const listenChangedNetworkInfo = () =>{
    return new Promise(async (resolve, reject) => {
      emitter.on('onChangedNetworkInfo', (payload) => {  
        logger.log('listenChangedNetworkInfo.onChangedNetworkInfo => payload : ', payload)
        if (payload.data) {
          logger.log(`listenChangedNetworkInfo.onChangedNetworkInfo => payload : ${JSON.stringify(payload)}`);
          let decoded = JSON.parse(decodeURIComponent(window.atob(payload.data)));
          logger.log(`listenChangedNetworkInfo.onChangedNetworkInfo => decoded : ${JSON.stringify(decoded)}`);
          if(decoded.type != 'none'){
            emitter.emit('hideLoading');
            emitter.off('onChangedNetworkInfo');
          }
          resolve("");
        }    
      });

      emitter.emit('showLoading');
      setTimeout(() => {
        emitter.emit('hideLoading');
        emitter.off('onChangedNetworkInfo');
        reject("네트워크 변경에 실패했습니다.");
      }, 10000);
    });
  };

  return {
    getWifiInfo,
    connectWifi,
    settingController,
    getControllerInfo,
    requestTransferController,
    requestRegistController,
    listenChangedNetworkInfo,
    disConnectWifi
  };
}
