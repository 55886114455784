<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem>
        <div class="flex-fill">
          <div class="fw-600" style="font-size: 1rem">
            <img
              :src="require('@/assets/images/curtain.svg')"
              style="
                margin-right: 0.5rem;
                height: 16px;
                width: 16px;
                margin-top: -4px;
              "
            />{{ props.name
            }}<i
              class="fa-solid fa-pen-to-square ms-3 text-primary"
              style="cursor: pointer"
              @click="onEditDeviceClick"
            ></i>
          </div>
        </div>
        <div>
          <OnOffButton
            :value="getPower"
            onText="열림"
            offText="닫힘"
            name="curtain-item"
            :index="props.deviceId"
            @change="onPowerClicked"
          />
        </div>
      </ListGroupItem>
    </div>
    <DeviceItemModal
      v-if="deviceItemModal.visible"
      :visible="deviceItemModal.visible"
      :okCallback="onUpdateDeviceName"
      :cancelCallback="deviceItemModal.hide"
      :deviceName="props.name"
      :groupId="props.groupId"
    />
  </div>
</template>

<script setup>
import { defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import DeviceItemModal from './DeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { DeviceApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { emit } = getCurrentInstance();
const store = useStore();
const props = defineProps(['name', 'groupId', 'deviceId']);
const deviceItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const device = computed(() => {
  return store.getters['home/getCurrentGroup'].devices.find(
    (d) => d._id === props.deviceId,
  );
});
const getPower = computed(() => {
  return device.value.item.onoff === 1;
});
const onPowerClicked = (e) => {
  emit('onPowerClick', { onoff: getPower.value ? 0 : 1 });
};

const onEditDeviceClick = () => {
  deviceItemModal.show();
};

const onUpdateDeviceName = (value) => {
  requestUpdateDevice({
    name: value,
  });
  deviceItemModal.hide();
};

const requestUpdateDevice = async (params) => {
  try {
    const result = await Axios.patch(
      DeviceApi.UPDATE_DEVICE.replace(':id', props.deviceId),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateDevice', {
        groupId: props.groupId,
        id: props.deviceId,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '장치 정보 수정에 실패했습니다.',
    });
  }
};
</script>
