<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="text-break">
          <div class="flex-fill d-flex">
            <div class="text-gray-600" style="font-size: 1rem; margin-right: 0.5rem;">
              {{ props.group?.name + ": " || "" }}
            </div>
            <div style="font-size: 1rem">
              {{ props.device?.name || "" }}
            </div>
          </div>
        </div>
        <div class="flex-fill" />
        <OnOffSwitch :value="powerValue" @onValueChange="onPowerClicked" />
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        :style="`padding-top: 0rem; padding-bottom: 0rem;${powerValue ? '' : 'background-color: #eeeeee;'}`"
      >
        <Slider
          name="밝기"
          id="color-bright"
          :min="10"
          :max="100"
          :step="10"
          :disabled="!powerValue"
          :default="brightValue"
          @onSliderChanged="onBrightChanged"
        />
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        :style="`padding-top: 0rem; padding-bottom: 0rem;${powerValue ? '' : 'background-color: #eeeeee;'}`"
      >
        <Slider
          name="색온도"
          id="color-temp"
          :min="0"
          :max="20"
          :step="2"
          :disabled="!powerValue"
          :default="colorValue"
          v-on:onSliderChanged="onColorChanged"
        />
      </ListGroupItem>

      <!-- <div class="list-group-item d-flex align-items-center text-gray-800">
        {{ props.data }}
      </div> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  watch,
} from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DeviceType } from '@/common/constants';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import OnOffSwitch from '@/common/components/widgets/OnOffSwitch.vue';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());

const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['group', 'device']);
const store = useStore();

const brightValue = computed(() => {
  return props.device.item.bright;
});
const colorValue = computed(() => {
  return props.device.item.color;
});
const powerValue = computed(() => {
  return props.device.item.onoff === 1;
});

const onPowerClicked = (value) => {
  const payload = { onoff: powerValue.value ? 0 : 1 };

  if (props.device.type == 1) {
    var data = {
      ports: props.device.item.ports.map((p) => p),
      onoff: payload.onoff,
      bright: props.device.item.bright,
      color: props.device.item.color,
    };
  } else {
    var data = { serial: props.device.item.serial, ...payload };
  }
  mqttControl.onPublish(props.device.controllerId, props.device._id, {
    serial: props.device.controllerId,
    cmd: 'c',
    type: props.device.type,
    data: data,
  });
}

const onBrightChanged = (e) => {
  mqttControl.onPublish(props.device.controllerId, props.device._id, {
    serial: props.device.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: props.device.item.ports.map((p) => p),
      onoff: props.device.item.onoff,
      bright: parseInt(e),
      color: props.device.item.color,
    },
  });
};

const onColorChanged = (e) => {
  mqttControl.onPublish(props.device.controllerId, props.device._id, {
    serial: props.device.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: props.device.item.ports.map((p) => p),
      onoff: props.device.item.onoff,
      bright: props.device.item.bright,
      color: parseInt(e),
    },
  });
};
</script>
