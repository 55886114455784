import { ref, computed } from 'vue';

export default function useModal() {
  let visible = ref(false);

  const show = () => {
    visible.value = true;
  };
  const hide = () => {
    visible.value = false;
  };

  return { visible, show, hide };
}
