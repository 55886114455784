import { useMQTT } from 'mqtt-vue-hook'
import { ref } from 'vue';

const mqttHook = useMQTT()
const mqttSubscribed = ref(false);
const mqttConnected = ref(false);
const currentTopics = ref([]);

mqttHook.registerEvent("on-connect", (topic, message) => {
  console.log("mqtt event: on-connect");
  // console.log("topic: " + topic, ", message: " + message);
  mqttConnected.value = true;
});
mqttHook.registerEvent("on-disconnect", async (topic, message) => {
  console.log("mqtt event: on-disconnect");
  // console.log("topic: " + topic, ", message: " + message);
  // await mqttHook.clearEvent()
  mqttConnected.value = false;
});
mqttHook.registerEvent("on-reconnect", (topic, message) => {
  console.log("mqtt event: on-reconnect");
  // console.log("topic: " + topic, ", message: " + message);
  mqttConnected.value = true;
});
mqttHook.registerEvent("on-connect-fail", (topic, message) => {
  console.log("mqtt event: on-connect-fail");
  // console.log("topic: " + topic, ", message: " + message);
  mqttConnected.value = false;
});

export default {

  install(Vue) {
     
    Vue.config.globalProperties.mqtt = {
      connect: () => {
        mqttHook.connect(process.env.VUE_APP_MQTT_DOMAIN, {
          clean: false,
          keepalive: 60,
          clientId: `${process.env.VUE_APP_MQTT_CLIENT_ID}_${Math.random()
            .toString(16)
            .substring(2, 10)}`,
          connectTimeout: 4000,
          rejectUnauthorized : false
        });
      },

      disconnect: () => {
        mqttHook.disconnect();
      },

      subscribe : async(topics) => {
        return new Promise(async (resolve, reject) => {
          try {
            await mqttHook.subscribe(
              // ['$'+topic],          
              topics,
              0, undefined ,      
              () => {
                  console.log('mqtt subscribe callback. topics:',topics);
                  currentTopics.value = topics;
                  mqttSubscribed.value = true;
              },'substring_key'
            )
            
            // console.log('topics:',topics)
            resolve("");
          } catch(e) {
            reject(e);
          }
        })
      },

      unSubscribe : async(topic) => {
        return new Promise(async (resolve, reject) => {
          try {
            await mqttHook.unSubscribe(topic, undefined, (error, packet) => {
              console.log('mqtt unsubscribe callback. topic:' + topic)
              currentTopics.value = [];
              mqttSubscribed.value = false;
            });
            await mqttHook.unRegisterEvent(topic)
            // await mqttHook.clearEvent()                 //등록된 이벤트 일괄제거
            resolve("");
          } catch(e) {
            reject(e);
          }
        })
      },

      

      publish : (topic,payload) =>{
        console.log('mqtt.js => publish : topic : ','$'+topic,',payload:',payload)
        mqttHook.publish(
          '$'+topic,
          JSON.stringify(payload),
          0,
          undefined,
          () => {
              // console.log('published!')
          }
      )
      },

      isSubscribed: () => {
        return currentTopics.value.length === 0 ? true : mqttSubscribed.value;
      },

      isConnected: () => {
        return mqttConnected.value;
      }
    }
  }
}