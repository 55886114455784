const popup = {
  state: {
    visible: false,
    title: '알림',
    description: '',
    okText: '확인',
    cancelText: '취소',
    clickable: true,
    slot: undefined,
    okCallback: undefined,
    cancelCallback: undefined,
    closeCallback: undefined,
    showCancel: true,
  },
  getters: {
    visible: (state) => state.visible,
    title: (state) => state.title,
    description: (state) => state.description,
    okText: (state) => state.okText,
    cancelText: (state) => state.cancelText,
    clickable: (state) => state.clickable,
    slot: (state) => state.slot,
    okCallback: (state) => state.okCallback,
    cancelCallback: (state) => state.cancelCallback,
    closeCallback: (state) => state.closeCallback,
    showCancel: (state) => state.showCancel,
  },
  mutations: {
    setVisible: (state, payload) => (state.visible = payload),
    setTitle: (state, payload) => (state.title = payload),
    setDescription: (state, payload) => (state.description = payload),
    setOkText: (state, payload) => (state.okText = payload),
    setCancelText: (state, payload) => (state.cancelText = payload),
    setClickable: (state, payload) => (state.clickable = payload),
    setSlot: (state, payload) => (state.slot = payload),
    setOkCallback: (state, payload) => (state.okCallback = payload),
    setCancelCallback: (state, payload) => (state.cancelCallback = payload),
    setCloseCallback: (state, payload) => (state.closeCallback = payload),
    setShowCancel: (state, payload) => (state.showCancel = payload),
    init: (state) => {
      state.title = '알림';
      state.description = '';
      state.okText = '확인';
      state.cancelText = '취소';
      state.clickable = true;
      state.slot = undefined;
      state.okCallback = undefined;
      state.cancelCallback = undefined;
      state.closeCallback = undefined;
      state.showCancel = true;
    },
  },
  actions: {
    show: (store, payload) => {
      if (payload?.title?.length > 0) {
        store.commit('setTitle', payload?.title);
      }
      if (payload?.description?.length > 0) {
        store.commit('setDescription', payload?.description);
      }
      if (payload?.okText?.length > 0) {
        store.commit('setOkText', payload?.okText);
      }
      if (payload?.cancelText?.length > 0) {
        store.commit('setCancelText', payload?.cancelText);
      }
      if (payload?.clickable !== undefined) {
        store.commit('setClickable', payload?.clickable);
      }
      if (payload?.slot !== undefined) {
        store.commit('setSlot', payload?.slot);
      }
      if (payload?.okCallback) {
        store.commit('setOkCallback', payload?.okCallback);
      }
      if (payload?.cancelCallback) {
        store.commit('setCancelCallback', payload?.cancelCallback);
      }
      if (payload?.closeCallback) {
        store.commit('setCloseCallback', payload?.closeCallback);
      }
      if (payload?.showCancel !== undefined) {
        store.commit('setShowCancel', payload?.showCancel);
      }
      store.commit('setVisible', true);
    },
    hide: (store) => {
      store.commit('setVisible', false);
      store.commit('init');
    },
  },
};

export default popup;
