<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break">
          <div style="font-size: 1rem">{{ props.data.title }}</div>          
        </div>
        <!-- <div @click.stop="onDeleteClick">
          <i class="fa-solid fa-x"></i>
        </div> -->
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        <div class="flex-fill" @click="onDetailClick">
          <div
            class="fw-400 text-gray-800 text-begin me-2"
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            {{props.data.content}}            
          </div>
        </div>
        <div >
          <!-- {{props.data.createdAt | moment('dddd')}} -->
        </div>
      </ListGroupItem>
     
      
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, computed, getCurrentInstance ,ref,reactive} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Axios, { NoticeApi } from '@/api';
import moment from 'moment'
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

let noticeItem = ref(undefined);
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['index', 'data']);

onMounted(() => {
  console.log('ZoneItem.onMounted().props =>', props.data.controllers);  
});
const hasController = computed(() =>{
  return props.data.controllers.length > 0
})

const requestNoticeItem = async (id) => {
  try {
    const result = await Axios.get(NoticeApi.NOTICE.replace(':id',id));    
    if (result.resultCode === '0000') {
      noticeItem.value = result.resultData;
      return;
    }
    if (result.resultMessage) {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
    return false;
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '공지사항 상세 조회에 실패했습니다.',
    });
    return false;
  }
};
</script>
