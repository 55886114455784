<template>
  <div class="col-md-6 mb-1">
    <div class="list-group" style="border-radius: 1.2rem;">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break" v-touch:hold="onTouchHold">
          <div style="font-size: 1rem">{{ props.data.name }}</div>
        </div>
        <div @click.stop="onDeleteClick">
          <i class="fa-solid fa-x"></i>
        </div>
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        <div class="flex-fill" @click="onDetailClick">
          <div
            class="fw-400 text-gray-800 text-begin me-2"
          >
            컨트롤러 등록 여부 :
            <span
              :class="hasController ? 'text-primary' : 'text-danger'">{{ hasController ? '&nbspO' : '&nbspX' }}</span
            >
          </div>
        </div>
        <div>
          <button
            v-if="props.data.controllers.length > 0"
            type="button"
            class="btn btn-outline-primary"
            style="
              padding-top: 1px;
              padding-bottom: 1px;
            "
            @click="onSettingControllerClick"
          >
            설정
          </button>

          <button
            v-else
            type="button"
            class="btn btn-outline-primary"
            style="
              padding-top: 1px;
              padding-bottom: 1px;
            "
            @click="onRegistControllerClick"
          >
            추가
          </button>
        </div>
      </ListGroupItem>
      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        등록된 방 수 : {{ props.data.groups.length }}개
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        설정 모드 : 
        <span :class="props.data.isEditMode ? 'text-danger' : 'text-primary'">{{ props.data.isEditMode ? '&nbsp활성화' : '&nbsp비활성화' }}</span>                
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
        v-if="props.data.isEditMode"
      >
        <span style="font-size:10pt" :class="!hasController ? 'text-danger' : 'text-primary'">{{ props.data.isEditMode ? '&nbsp※ 컨트롤러 미등록시에는 네트워크 설정을 할 수 없습니다.\n컨트롤러 추가 버튼을 눌러 추가 후 설정을 진행하십시요.' : '' }}</span>
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
        v-if="props.data.isEditMode"
      >
        <span style="font-size:10pt" :class="props.data.isEditMode ? 'text-danger' : 'text-primary'">{{ props.data.isEditMode ? '&nbsp※ 설정 모드 활성화시에는 앱을 통한 장치 제어가 제한됩니다.' : '' }}</span>
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        <div class="flex-fill" @click="onDetailClick">
          <div
            class="fw-400 text-green text-end me-2"
            style="font-size: 0.8rem"
          >
            상세보기
          </div>
        </div>
        <div>
          <i
            class="fa fa-chevron-right text-green"
            style="font-size: 0.8rem"
          ></i>
        </div>
      </ListGroupItem>
    </div>
    <ZoneModal
      v-if="zoneModal.visible"
      :visible="zoneModal.visible"
      :okCallback="onUpdateZoneName"
      :cancelCallback="zoneModal.hide"
      :zoneName="props.data?.name"
    />
  </div>
</template>

<script setup>
import { onMounted, defineProps, computed, getCurrentInstance ,reactive} from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Axios, { ZoneApi } from '@/api';
import useMqttControl from '@/common/hooks/useMqttControl';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import ZoneModal from '@/views/rooms/components/ZoneModal.vue';
import useModal from '@/common/hooks/useModal';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['index', 'data', 'onZoneUpdated']);
const store = useStore();
const zoneModal = reactive(useModal());
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});

onMounted(() => {
  console.log('ZoneItem.onMounted().props =>', props.data.controllers);
});
const hasController = computed(() =>{
  return props.data.controllers.length > 0
})
const onDetailClick = () => {
  console.log(
    'ZoneItem.onDetailClick => ',
    `/settings/zones/${props.data._id}`,
  );
  // if (props.data.controllers.length == 0) {
  //   emitter.emit('showPopAlert', {
  //     title: '컨트롤러 미등록',
  //     description: `컨트롤러를 먼저 등록해야합니다.\n컨트롤러 등록 화면으로 이동합니다.`,
  //     okCallback: () => {
  //       router.push(`/settings/zones/${props.data._id}/regist-controllers`);
  //     },
  //     cancelCallback:() =>{

  //     }
  //   });
  //   return;
  // }

  if(!props.data.isEditMode){
    emitter.emit('showPopAlert', {
      title: '세팅 모드',
      description: `세팅모드 화면으로 이동하시겠습니까?.\n세팅모드 활성화시 모바일 기기에서 컨트롤이 제한됩니다.`,
      okCallback: async() => {
        const payload = {isEditMode:true}
        const result = await Axios.patch(
          ZoneApi.UPDATE_ZONE.replace(':id', props.data._id),
          payload,
        );
        if (result.resultCode === '0000') {
          mqttControl.onPublishZone(props.data._id,payload)
          router.push(`/settings/zones/${props.data._id}/groups`);
        }
      },
      cancelCallback:() =>{}
    });
    return
  }

  router.push(`/settings/zones/${props.data._id}/groups`);
  
};
const onRegistControllerClick = () => {
  router.push(`/settings/zones/${props.data._id}/regist-controllers`);
}
const onSettingControllerClick = () =>{
  router.push(`/settings/zones/${props.data._id}/setting-controllers`);
}

const onDeleteClick = () => {
  console.log('ZoneItem.onDeleteClick =>', {
    name: props.data.name,
    zoneId: props.data._id,
  });

  emitter.emit('showPopConfirm', {
    title: '영역 삭제',
    description: `${props.data.name} 영역을 삭제하시겠습니까?`,
    okText: "삭제",
    cancelText: '취소',
    okCallback: () => setTimeout(() => emit('onDeleteClick', { name: props.data.name, zoneId: props.data._id }), 100)
  });
};

const onTouchHold = () => {
  zoneModal.show();
}

const onUpdateZoneName = (value) => {
  requestUpdateZone({
    name: value,
  });
  zoneModal.hide();
};

const requestUpdateZone = async (params) => {
  try {
    const result = await Axios.patch(
      ZoneApi.UPDATE_ZONE.replace(':id', props.data._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateZone', {
        id: props.data._id,
        data: params,
      });
      emit('onZoneUpdated');
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '영역 정보 수정에 실패했습니다.',
    });
  }
};
</script>
