<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">{{ props.step }}. 영역 선택</h4>
      <p class="mb-0">
        1. 목록에서 컨트롤러를 등록할
        <code style="font-size: 100%">영역을 선택</code>해주세요. 영역이 없으면
        아래의 <code style="font-size: 100%">영역 버튼</code>을 눌러
        <code style="font-size: 100%">영역을 추가</code>해주세요.
      </p>
      <p class="mb-3">
        2. 아래의
        <code style="font-size: 100%">다음 버튼</code>을 눌러서 컨트롤러 등록
        단계에 진입해주세요.
      </p>
      <CardBody>
        <button
          type="button"
          class="btn btn-outline-primary mb-1 me-1"
          style="min-width: 50px"
          v-for="(item, index) in templateZoneNames"
          :key="index"
          @click="onAddZoneClick(item)"
        >
          {{ item }}
        </button>
      </CardBody>
    </div>

    <hr class="mb-3" v-if="props.zones?.length > 0" />

    <div class="row" v-if="props.zones?.length > 0">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>영역 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(zone, index) in props.zones"
            :key="zone._id"
          >
            <div class="form-check flex-1 text-break">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="zone._id"
                @change="props.handleChange"
                :checked="zone._id == props.selectedZone?._id"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ zone.name }}
              </label>
            </div>
            <i
              class="fa-solid fa-x"
              v-if="zone.controllers.length === 0"
              @click="() => onDeleteZoneClick(zone._id)"
            ></i>
          </ListGroupItem>
        </div>
      </div>
    </div>

    <PopDialog
      title="영역추가"
      :visible="addZoneModal.visible"
      :okCallback="onAddZoneConfirmClick"
      :cancelCallback="addZoneModal.hide"
    >
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="영역 이름을 입력해주세요."
        v-model="addZoneName"
      />
    </PopDialog>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Template } from '@/common/constants';
import Axios, { ZoneApi } from '@/api';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
const props = defineProps(['step', 'handleChange', 'zones', 'selectedZone']);
const store = useStore();
const router = useRouter();
const addZoneModal = reactive(useModal());
const templateZoneNames = computed(() => Template.ZoneNames);
let addZoneName = ref('');

const onAddZoneClick = (name) => {
  if (name === Template.ZoneNames[Template.ZoneNames.length - 1]) {
    addZoneModal.show();
    return;
  }

  requestAddZone(name);
};

const onDeleteZoneClick = (zoneId) => {
  requestDeleteZone(zoneId);
};

const onAddZoneConfirmClick = async () => {
  if (addZoneName.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: "영역 이름을 입력해주세요.",
    });
    return;
  }

  addZoneModal.hide();
  requestAddZone(addZoneName.value);
};

const requestAddZone = async (name) => {
  try {
    const result = await Axios.post(ZoneApi.REGIST_ZONE, { name: name });
    if (result.resultCode === '0000') {
      store.commit('home/addZone', result.resultData);
      return { zone: result.resultData, result: true };
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
    return { result: false, zone: undefined };
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '영역 추가 중 오류가 발생하였습니다.',
    });
  }
};

const requestDeleteZone = async (zoneId) => {
  try {
    const result = await Axios.delete(
      ZoneApi.DELETE_ZONE.replace(':id', zoneId),
    );
    if (result.resultCode === '0000') {
      store.commit('home/deleteZone', zoneId);
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '영역 삭제 중 오류가 발생하였습니다.',
    });
  }
};
</script>
