<template>    
  <div class="list-group" ref="boilerItem" style="border-radius: 1.2rem; margin-top: 1px;" @click="onBoilerDevicePowerClicked" v-touch:press="onTouchDown" v-touch:drag="onTouchDrag" v-touch:release="onTouchRelease" v-touch:hold="onTouchHold">
    <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background: ${getPower ? `linear-gradient(to right, #ffe082 ${percent}%, #fff8e1 ${percent}%)` : 'inherits'};`">
      <i class="fa-solid fa-temperature-three-quarters me-2" />
      <div class="flex-fill" style="font-size: 0.8rem">
        {{ device.name }}
        <br>
        {{ getPower ? currentTempText : "" }}
      </div>
      <!-- <div class="mx-1" @click.stop="onBoilerDeviceInfoClicked">
        <i class="fa fa-ellipsis" />
      </div> -->
    </div>
  </div>
        
  <BoilerDeviceItemModal
    v-if="boilerDeviceItemModal.visible"
    :visible="boilerDeviceItemModal.visible"
    :cancelCallback="boilerDeviceItemModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
    :deviceId="props.deviceId"
    :handlePowerClick="onBoilerDevicePowerClicked"
  />
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import BoilerDeviceItemModal from './BoilerDeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import useMqttControl from '@/common/hooks/useMqttControl';
import useDeviceTouch from '@/common/hooks/useDeviceTouch';

const mqttControl = reactive(useMqttControl());
const props = defineProps(['roomId', 'roomName', 'deviceId']);
const store = useStore();
const boilerDeviceItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const boilerItem = ref(null);
const percent = ref(0.0);
const currentTemp = ref(0);
const { 
  handlePower, 
  handleTouchDown, 
  handleTouchDrag, 
  handleTouchRelease,
  handleTouchHold
} = useDeviceTouch(props.roomId);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const device = computed(() => {
  return devices.value.find(
    (d) => d._id === props.deviceId,
  );
});

const getPower = computed(() => {
  return device.value.item.onoff === PowerState.On;
});

const currentTempText = computed(() => {
  return `${parseInt(currentTemp.value)}°C`;
})

onMounted(() => {
  // if (!isMobile()) {
  //   window.addEventListener('mouseup', onMouseUp, false);
  //   window.addEventListener('mousemove', onMouseMove, false);
  // }
  currentTemp.value = device.value.item.temperature || 10;
  percent.value = (currentTemp.value - 10) / 30 * 100;
});

const onBoilerDevicePowerClicked = (e) => {
  handlePower(() => {
    if (zone.value.isEditMode) {
      emitter.emit('showPopAlert', {
        description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
      });
      return;
    }

    const data = { 
      serial: device.value.item.serial,    
      onoff: getPower.value ? 0 : 1,
      temperature: device.value.item.temperature || 0
    };

    mqttControl.onPublish(device.value.controllerId, device.value._id, {
      serial: device.value.controllerId,
      cmd: 'c',
      type: device.value.type,
      data: data,
    });
  });
};

const onBoilerDeviceInfoClicked = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  boilerDeviceItemModal.show();
};

const tempChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: device.value.type,
    data: {
      serial: device.value.item.serial,
      onoff: device.value.item.onoff,
      temperature: parseInt(currentTemp.value),
    },
  });
};

const onTouchDown = (e) => {
  if (getPower.value) {
    handleTouchDown(e);
  }
}

const onTouchDrag = (e) => {
  handleTouchDrag(e, (screenX, startX) => {
    percent.value = percent.value + (screenX - startX) / boilerItem.value.clientWidth * 100.0;
    percent.value = percent.value <= 0 ? 0 : percent.value;
    percent.value = percent.value >= 100 ? 100 : percent.value;
    currentTemp.value = (30 * percent.value * 0.01) + 10;
  });
}

const onTouchRelease = (e) => {
  handleTouchRelease(e, tempChanged);
}

const onTouchHold = (e) => {
  handleTouchHold(e, onBoilerDeviceInfoClicked);
}
</script>
