<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">영역 관리</h4>

    <div class="row">
      <div class="col-xl-6">        
        <div class="row" v-for="(item,index) in zones" :key="item._id">
          <!-- <ZoneItem :index="index" :data="item"/>           -->
        </div>
      </div>
    </div>

    <div>
      <button
            type="submit"
            class="btn btn-primary btn-lg d-block w-40 fw-40 mb-4"
            @click="onAddClick"
          >
            +
          </button>
    </div>
    <PopDialog
      title="영역 추가"
      :visible="addZoneModal.visible"
      :okCallback="onAddConfirmClick"
    >
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="영역명을 입력해주세요."
        v-model="zoneName"
      />
    </PopDialog>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  watch
} from 'vue';
import Axios, { ZoneApi } from '@/api';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
// import ZoneItem from '../settings/components/ZoneItem.vue';

const addZoneModal = reactive(useModal());
let zones = ref(undefined)
let zoneName = ref('');

onBeforeMount(() => {
  requestZones();
});

onMounted(()=>{
  console.log('AddZonePage.onMounted => zones : ',zones.value)
})

watch(zones,() =>{
  console.log('AddZonePage.watch => zones : ',zones.value)  
})

const onAddClick = () => {
  addZoneModal.show();
};

const onAddConfirmClick = () => {
  console.log(zoneName.value);
  requestAddZone()
  
};

const requestZones = async () => {
  try {
    Axios.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJuaWNrTmFtZSI6IlNjb3R0LUsiLCJpYXQiOjE2NzY5NTgwNTIsImV4cCI6MTY3NzA0NDQ1MiwiaXNzIjoiSW5TaG93In0.PSVPEFA0MGQliAnDg8iSO1DVmDG11KVH5nhygfVxeG0')
    const result = await Axios.get(ZoneApi.REGIST_ZONE);
    if (result.resultCode === '0000') {
      zones.value = result.resultData;
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '기존 영역 조회에 실패했습니다.',
    });
  }
};
const requestAddZone = async() =>{
  try{
    Axios.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAxLCJuaWNrTmFtZSI6IlNjb3R0LUsiLCJpYXQiOjE2NzY5NTgwNTIsImV4cCI6MTY3NzA0NDQ1MiwiaXNzIjoiSW5TaG93In0.PSVPEFA0MGQliAnDg8iSO1DVmDG11KVH5nhygfVxeG0')
    const result = await Axios.post(ZoneApi.REGIST_ZONE,{name:zoneName.value})
    if (result.resultCode === '0000') {    
      addZoneModal.hide();        
      return
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  }catch(e){
    console.log(e)
    emitter.emit('showPopAlert', {
      description: '영역 추가중 오류가 발생하였습니다.',
    });
  }
}
</script>
