import axios from 'axios';

export const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

let headers = {
  Accept: 'application/json',
  Authorization: '',
};

export const client = axios.create();

const request = (method, url, args, isBlob = false) => {
  let params = {};
  if (method === METHOD.GET) {
    params = { params: args };
  } else {
    params = { data: args };
  }
  if (!args) {
    params = {};
  }
  if (isBlob) {
    params = { ...params, responseType: 'blob' };
  }

  const axiosConfig = {
    headers: headers,
    method,
    url,
    timeout: 10000,
    ...params,
  };

  console.info('axios config:', JSON.stringify(axiosConfig));
  return client(axiosConfig);
};

const requestFunc = async (method, url, args, isBlob = false) => {
  const response = await request(
    method,
    `${process.env.VUE_APP_PROXY_DOMAIN}/api${url}`,
    args,
    isBlob,
  );
  console.info('response.data:', JSON.stringify(response?.data));
  return response.data;
};

const requestAuthFunc = async (method, url, args, isBlob = false) => {
  const response = await request(
    method,
    `${process.env.VUE_APP_PROXY_DOMAIN}/api${url}`,
    args,
    isBlob,
  );
  console.info('response.data:', JSON.stringify(response?.data));
  return response.data;
};

const requestSmartHomeFunc = async (method, url, args, isBlob = false) => {
  const response = await request(
    method,
    `${process.env.VUE_APP_PROXY_DOMAIN}/api${url}`,
    args,
    isBlob,
  );
  console.info('response.data:', JSON.stringify(response?.data));
  return response.data;
};

const invokeRequestFunc = async (method, url, params, type = '') => {
  if (type === 'auth') {
    return await requestAuthFunc(method, url, params);
  } else if (type === 'smarthome') {
    return await requestSmartHomeFunc(method, url, params);
  }
  return await requestFunc(method, url, params);
};

const Axios = {
  get: async (url, params, type = '') => {
    return await invokeRequestFunc(METHOD.GET, url, params, type);
  },
  post: async (url, params, type = '') => {
    return await invokeRequestFunc(METHOD.POST, url, params, type);
  },
  put: async (url, params, type = '') => {
    return await invokeRequestFunc(METHOD.PUT, url, params, type);
  },
  patch: async (url, params, type = '') => {
    return await invokeRequestFunc(METHOD.PATCH, url, params, type);
  },
  delete: async (url, params, type = '') => {
    return await invokeRequestFunc(METHOD.DELETE, url, params, type);
  },
  setToken: (token, clear = false) => {
    if (clear) {
      headers.Authorization = '';
    } else {
      headers.Authorization = `Bearer ${token}`;
    }
  },
  clearToken: () => (headers.Authorization = ''),
};

export default Axios;
export * from './apiUrls';
