<template>
  <div class="container" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div
        :class="`col-${props.nameWidth}`"
        v-if="props.showName"
        :style="`margin-top: 6px`"
      >
        <label :for="props.id" class="form-label mb-0 fw-normal">{{
          props.name
        }}</label>
      </div>
      <div class="col" :style="`margin-top: ${visibleTooltip ? -10 : 5}px;`">
        <div
          :style="`left: ${tooltipLeft}px; position:relative; width: 25px; height: ${
            visibleTooltip ? 15 : 0
          }px; z-index:9999; background-color: rgba(102, 102, 102, 0.8); border: 0px solid #ffffff; color: #ffffff; border-radius: 3px; font-size: ${
            visibleTooltip ? 10 : 0
          }px`"
          ref="sliderTooltip"
          :value="value"
          class="range-slider-tooltip text-center"
        >
          {{ textList.length > (value - min) / step ? textList[(value - min) / step] : value }}
        </div>
        <input
          ref="sliderInput"
          type="range"
          :class="`form-range ${rangeType} range-slider`"
          :min="props.min"
          :max="props.max"
          :step="props.step"
          :id="props.id"
          v-model="value"
          @change="onSliderChanged"
          @mousedown="showTooltip"
          @mouseup="hideTooltip"
          v-touch:press="showTooltip"
          v-touch:release="hideTooltip"
          :disabled="props.disabled"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  getCurrentInstance,
  computed,
  watch,
  onMounted,
  nextTick,
} from 'vue';
const { emit } = getCurrentInstance();
// v-model="props.value"
var value = ref(props.default);
var tooltipLeft = ref(0);
var visibleTooltip = ref(false);
const sliderInput = ref();
const sliderTooltip = ref();
const defaultValue = computed(() => props.default);

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: '',
  },
  id: {
    type: String,
    required: true,
    default: 'slider-widget',
  },
  default: {
    type: Number,
    required: true,
    default: 0,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 10,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  type: {
    type: String,
    default: 'normal',
  },
  showName: {
    type: Boolean,
    default: true,
  },
  nameWidth: {
    type: Number,
    default: 3,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textList: {
    type: Array,
    default: []
  }
});

// console.log(`col-${props.nameWidth}`);

const rangeType = computed(() => {
  if (props.type === 'white') {
    return 'range-white';
  }
  return 'range-normal';
});

const showTooltip = () => {
  visibleTooltip.value = true;
  nextTick(() => (tooltipLeft.value = getTooltipPosition()));
};

const hideTooltip = () => {
  visibleTooltip.value = false;
};

const onSliderChanged = () => {
  console.log(props.disabled)
  if (props.disabled) {
    return;
  }
  // console.log('Slider.onSliderChanged => value:', value.value);
  emit('onSliderChanged', value.value);
};

const getTooltipPosition = () => {
  let percentage = (100 / (props.max - props.min)) * (value.value - props.min);
  let sliderWidth = sliderInput.value.offsetWidth;
  let tooltipWidth = sliderTooltip.value.offsetWidth;

  let calc = sliderWidth - tooltipWidth + 6;
  let positionCalc =
    (percentage / 100) * calc -
    (6 / ((props.max - props.min) / props.step)) *
      ((props.max - props.min - (value.value - props.min)) / props.step);
  return positionCalc;
};

onMounted(() => {
  tooltipLeft.value = getTooltipPosition();
});

watch(value, () => {
  // console.log()
  tooltipLeft.value = getTooltipPosition();
});

watch(defaultValue, () => {
  if (value.value !== defaultValue.value) {
    value.value = defaultValue.value;
  }
});
</script>

<style scoped>
.range-white {
  background-color: transparent;
}

.range-white::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.range-white::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #555555 0%, #666666 100%);
  margin-top: -3px;
}

.range-white:focus::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #666666 0%, #777777 100%);
  margin-top: -3px;
}

.range-normal {
  background-color: transparent;
}

.range-normal::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid #dddddd;
}

.range-normal::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #555555 0%, #666666 100%);
  margin-top: -3px;
}

.range-normal:focus::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, rgb(102, 102, 102) 0%, #777777 100%);
  margin-top: -3px;
}

.range-slider-holder {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 84px;
}

.range-slider-holder p {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0;
  padding: 18px 18px 18px 0;
}

.range-slider-holder p:last-child {
  padding: 18px 0 18px 18px;
}

.range-slider {
  width: 100%;
}
</style>
