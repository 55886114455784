<template>
  <div>
    <div class="col-xl-9">
      <div
        :class="`overflow-hidden fs-13px border-0`"
        style="min-height: 30px; margin-bottom: -10px"
      >
        <div>
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h1 style="margin-bottom: 0px">
                {{ group?.name || ''
                }}<i
                  class="fa-solid fa-pen-to-square ms-3 text-primary"
                  style="cursor: pointer"
                  @click="onEditGroupClick"
                  v-if="zone?.isOwner"
                ></i>
              </h1>
            </div>
            <OnOffButton
              name="group-head"
              :index="group?._id"
              :height="30"
              :paddingTop="4"
              :value="getPower"
              @change="onPowerClicked"
            />
          </div>
          <!-- <p>
            Card widget is created by using existing Bootstrap
            <code>.card</code> component with <code>.card-img</code>,
            <code>.card-img-overlay</code> and <code>.d-flex</code> utilities.
          </p> -->
        </div>
      </div>
    </div>
    <hr class="mb-4" v-if="devices?.length > 0" />
    <div class="row">
      <DeviceItem
        v-for="item in devices"
        :key="item._id"
        :type="item.type"
        :groupId="group?._id"
        :deviceId="item._id"
        :name="item.name"
        :device="item"
      />
      <!-- {{ item.controllerId }}:{{ item.item }} -->
    </div>
    <RoomItemModal
      v-if="roomItemModal.visible"
      :visible="roomItemModal.visible"
      :okCallback="onUpdateRoomName"
      :cancelCallback="roomItemModal.hide"
      :roomName="group?.name || ''"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed, reactive, onBeforeMount, getCurrentInstance } from 'vue';

import DeviceItem from './components/DeviceItem.vue';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import useMqttControl from '@/common/hooks/useMqttControl';
import RoomItemModal from './components/RoomItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { GroupApi } from '@/api';

const mqttControl = reactive(useMqttControl());
const store = useStore();
const router = useRouter();
const route = useRoute();
const roomItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

onBeforeMount(() => {
  console.log('RoomsInfoPage.onBeforeMount().route.params.id =>', route.params);
  console.log('RoomsInfoPage.onBeforeMount().group =>', group.value);
});

// onMounted(() =>{

// })

const getPower = computed(() => {
  return devices.value.some((d) => d.item.onoff === 1);
});

const group = computed(() => {
  return store.getters['home/getCurrentGroup'];
});

const devices = computed(() => {
  return group.value?.devices || [];
});

const roomId = computed(() => {
  return router && router.params && router.params.roomId
    ? router.params.roomId
    : undefined;
});

const onPowerClicked = () => {
  let onoff = getPower.value ? 0 : 1;
  console.log('RoomsInfoPage.onPowerClicked => change : ', onoff);

  devices.value.forEach((device) => {
    if (device.type == 1) {
      var data = {
        ports: device.item.ports.map((p) => p),
        onoff: onoff,
        bright: device.item.bright,
        color: device.item.color,
      };
    } else {
      var data = { onoff: onoff, serial: device.item.serial };
    }

    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: device.type,
      data: data,
    });
  });
};

const onEditGroupClick = () => {
  roomItemModal.show();
};

const onUpdateRoomName = (value) => {
  requestUpdateRoom({
    name: value,
  });
  roomItemModal.hide();
};

const requestUpdateRoom = async (params) => {
  try {
    const result = await Axios.patch(
      GroupApi.UPDATE_GROUP.replace(':id', group.value._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateGroup', {
        id: group.value._id,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 정보 수정에 실패했습니다.',
    });
  }
};
</script>
