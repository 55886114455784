export default {
  
  install(App) {
    const sendMessage = (message) => {
      const userAgent = navigator.userAgent.toLowerCase();
      // console.log(message)
      if (userAgent.indexOf('android') !== -1) {
        if(window.Log){return window.Log.postMessage(message);}          
      } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        if(window.webkit.messageHandlers.Log){return window.webkit.messageHandlers.Log.postMessage(message);}
      } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
        //  return window.opener.postMEssage(message);
      }
    }

    App.config.globalProperties.logger = {
      log : ((payload) =>{
        console.log('[LOG]'+payload)
        sendMessage('[LOG]'+payload)
      }),
      info : ((payload) =>{
        console.info('[INFO]'+payload)
        sendMessage('[INFO]'+payload)
      }),
      error : (payload) =>{
        console.error('[ERROR]'+payload)
        sendMessage('[ERROR]'+payload)
      },
    }
  }
}