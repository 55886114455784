<template>
  <ListGroupItem>
    <div class="flex-1 text-break">
      <div>{{ props.item.nickName }}</div>
      <div class="text-gray-600">{{ props.item.shareCode || '' }}</div>
    </div>
    <div>
      <a class="btn btn-default w-60px" @click="onUpdateClick">변경</a>
    </div>
  </ListGroupItem>
  <MemberModal
    v-if="updateModal.visible"
    :visible="updateModal.visible"
    :okCallback="onZoneUpdated"
    :cancelCallback="updateModal.hide"
    :user="props.item"
  />
</template>

<script setup>
import { reactive, getCurrentInstance, defineProps } from 'vue';
import Axios, { AuthApi } from '@/api';
import MemberModal from './MemberModal.vue';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const props = defineProps(['item', 'handleSharedZonesUpdated']);
const updateModal = reactive(useModal());

const onUpdateClick = () => {
  updateModal.show();
};

const onZoneUpdated = (params) => {
  if (props.handleSharedZonesUpdated) {
    props.handleSharedZonesUpdated(params);
  }
  updateModal.hide();
};
</script>
