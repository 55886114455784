<template>
  <div
    v-if="props.visible"
    class="modal fade show"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    style="display: block; background-color: rgba(0, 0, 0, 0.3)"
    @click.stop="onBackClick"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-sm"
      @click.stop="() => {}"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ props.title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click.stop="onCloseClick"
          ></button>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>

        <div class="modal-footer">
          <button
            v-if="props.showCancel"
            type="button"
            class="btn btn-secondary"
            @click.stop="onCancelClick"
          >
            {{ props.cancelText }}
          </button>
          <button type="button" class="btn btn-primary" @click.stop="onOkClick">
            {{ props.okText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '알림',
  },
  okText: {
    type: String,
    default: '확인',
  },
  cancelText: {
    type: String,
    default: '취소',
  },
  okCallback: {
    type: Function,
  },
  closeCallback: {
    type: Function,
  },
  cancelCallback: {
    type: Function,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  clickable: {
    type: Boolean,
    default: true,
  },
});

const onOkClick = () => {
  if (props.okCallback) {
    props.okCallback();
  }
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onCloseClick = () => {
  if (props.closeCallback) {
    props.closeCallback();
  } else if (props.cancelCallback) {
    props.cancelCallback();
  }
  //    else if (props.okCallback) {
  //     props.okCallback();
  //   }
};

const onBackClick = () => {
  if (props.clickable) {
    onCloseClick();
  }
};
</script>
