<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div
      id="pop-toast"
      class="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <i
          v-if="getters['toast/type'] === 'info'"
          class="fa-solid fa-circle-exclamation text-primary me-2"
        ></i>
        <i
          v-else-if="getters['toast/type'] === 'success'"
          class="fa-solid fa-circle-check text-success me-2"
        ></i>
        <i
          v-else-if="getters['toast/type'] === 'warning'"
          class="fa-solid fa-circle-exclamation text-warning me-2"
        ></i>
        <i
          v-else-if="getters['toast/type'] === 'error'"
          class="fa-solid fa-circle-xmark text-danger me-2"
        ></i>
        <i v-else class="fa-solid fa-bell text-muted me-2"></i>
        <strong class="me-auto">{{ getters['toast/title'] }}</strong>
        <!-- <small>11 mins ago</small> -->
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">
        <div v-for="(item, index) in descriptions" :key="index">
          {{ item
          }}<br
            v-if="descriptions.length > 1 && index < descriptions.length - 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const getters = computed(() => store.getters);

const descriptions = computed(() =>
  store.getters['toast/description']
    ?.split('\n')
    .filter((item) => item.length > 0)
    .map((item) => `${item}`),
);
</script>
