<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">공지사항</h4>
    <div class="row">
      <NoticeItem
        v-for="(item, index) in noticeItems"
        :key="item._id"
        :index="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Axios, { NoticeApi } from '@/api';
import NoticeItem from './components/NoticeItem.vue';
const { emitter, mobile, logger } = getCurrentInstance().appContext.config.globalProperties;

const store = useStore();
const router = useRouter();

let noticeItems = ref([]);

onBeforeMount(() => {
  requestNoticeItems()
});

const requestNoticeItems = async () => {
  try {
    const result = await Axios.get(NoticeApi.NOTICES);    
    if (result.resultCode === '0000') {
      noticeItems.value = result.resultData;
      return;
    }
    if (result.resultMessage) {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
    return false;
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '공지사항 조회에 실패했습니다.',
    });
    return false;
  }
};
</script>