import Axios, { AuthApi } from '@/api';

const auth = {
  state: {
    accessToken: '',
    refreshToken: '',
    account: {},
    tempSnsInfo: {},
  },
  getters: {
    isLogin: (state) =>
      state.accessToken?.length > 0 && state.refreshToken?.length > 0,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    account: (state) => state.account,
    tempSnsInfo: (state) => state.tempSnsInfo,
    isCertified: (state) => state.account.isCertified || false
  },
  mutations: {
    setAccessToken: (state, payload) => {
      state.accessToken = payload;
      Axios.setToken(payload);
    },
    setRefreshToken: (state, payload) => (state.refreshToken = payload),
    setTokens: (state, payload) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      Axios.setToken(payload.accessToken);
    },
    setAccount: (state, payload) => {
      state.account = payload;
    },
    setNickname: (state, payload) => {
      state.account.nickName = payload;
    },
    clearTokens: (state) => {
      state.accessToken = '';
      state.refreshToken = '';
      Axios.setToken('', true);
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
    },
    clearAccount: (state) => {
      state.account = {};
    },
    setTempSnsInfo: (state, payload) => {
      state.tempSnsInfo = payload;
    },
    clearTempSnsInfo: (state) => {
      state.tempSnsInfo = {};
    },
  },
  actions: {
    login: async (store, payload) => {
      const result = await Axios.post(AuthApi.SIGNIN, payload, 'auth');
      if (result.resultCode === '0000') {
        store.commit('setTokens', result.resultData);
        sessionStorage.setItem('accessToken', result.resultData.accessToken);
        sessionStorage.setItem('refreshToken', result.resultData.refreshToken);
        await store.dispatch('requestAccount');
        store.dispatch('home/fetchAllZones');
        store.dispatch('home/fetchFirmwares');
        store.dispatch('home/fetchProducts');
      }
      return result;
    },
    join: async (store, payload) => {
      const result = await Axios.post(AuthApi.SIGNUP, payload, 'auth');
      return result;
    },
    logout: (store) => {
      store.commit('clearTokens');
      store.commit('clearAccount');
      store.commit('home/initState');
    },
    requestAccount: async (store) => {
      const result = await Axios.get(AuthApi.ACCOUNT, undefined, 'auth');
      if (result.resultCode === '0000') {
        store.commit('setAccount', result.resultData);
      }
      return result;
    },
  },
};

export default auth;
