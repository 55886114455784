<template>
  <div class="mb-4" v-if="deviceCount > 0">
    <div class="d-flex mb-2">
      <div class="flex-fill" style="font-size: 1rem">
        {{ props.roomName }}
      </div>
    </div>

    <div class="container list-group-item mb-1" v-if="hasLight || hasBoiler">
      <div class="row">
        <RoomLightItem :roomName="props.roomName" :roomId="props.roomId" @onPowerClicked="onLightPowerClicked" v-if="hasLight" />
        <RoomBoilerItem :roomName="props.roomName" :roomId="props.roomId" @onPowerClicked="onBoilerPowerClicked" v-if="hasBoiler" />
      </div>
    </div>

    <div class="container list-group-item mb-4" v-if="hasCurtain || hasVentilator">
      <div class="row">
        <RoomCurtainItem :roomName="props.roomName" :roomId="props.roomId" @onPowerClicked="onCurtainPowerClicked" v-if="hasCurtain" />
        <RoomVentilatorItem :roomName="props.roomName" :roomId="props.roomId" @onPowerClicked="onVentilatorPowerClicked" v-if="hasVentilator" />
      </div>
    </div>

    <RoomItemModal
      v-if="roomItemModal.visible"
      :visible="roomItemModal.visible"
      :okCallback="onUpdateRoomName"
      :cancelCallback="roomItemModal.hide"
      :roomName="props.name"
    />
  </div>
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import { DeviceType, PowerState } from '@/common/constants';
import RoomItemModal from './RoomItemModal.vue';
import RoomLightItem from './RoomLightItem.vue';
import RoomBoilerItem from './RoomBoilerItem.vue';
import RoomCurtainItem from './RoomCurtainItem.vue';
import RoomVentilatorItem from './RoomVentilatorItem.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { GroupApi } from '@/api';

const { emit } = getCurrentInstance();

const router = useRouter();
const props = defineProps(['index', 'roomId', 'roomName', 'value', 'power']);
const store = useStore();
const roomItemModal = reactive(useModal());
const lightItemModal = reactive(useModal());
const boilerItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
// const lightItem = ref(null);
// const percent = ref(0.0);
// let mousePressed = false;
// let mouseDragged = false;
// let startX = 0.0;

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const curtainDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 3,
  );
});
const ventilatorDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 4,
  );
});

const getPower = computed(() => {
  return devices.value.some((d) => d.item?.onoff === PowerState.On);
});
const getCurtainPower = computed(() => {
  return curtainDevices.value.some((d) => d.item?.onoff === PowerState.On);
});
const getVentilatorPower = computed(() => {
  return ventilatorDevices.value.some((d) => d.item?.onoff === PowerState.On);
});

const hasLight = computed(() => {
  return devices.value.some((d) => d.type == DeviceType.Lamp);
});

const hasBoiler = computed(() => {
  return devices.value.some((d) => d.type == DeviceType.Boiler);
});

const hasCurtain = computed(() => {
  return devices.value.some((d) => d.type == DeviceType.Curtain);
});
const hasVentilator = computed(() => {
  return devices.value.some((d) => d.type == DeviceType.Ventilator);
});

const deviceCount = computed(() => {
  let count = hasLight.value ? 1 : 0;
  count += hasBoiler.value ? 1 : 0;
  count += hasCurtain.value ? 1 : 0;
  count += hasVentilator.value ? 1 : 0;
  return count;
});

// const percentText = computed(() => {
//   return `${parseInt(percent.value)}%`;
// })

// onMounted(() => {
//   window.addEventListener('mouseup', onMouseUp, false);
//   window.addEventListener('mousemove', onMouseMove, false);
// })

// onUnmounted(() => {
//   window.removeEventListener("mouseup", onMouseUp);
//   window.removeEventListener("mousemove", onMouseMove);
// })

// const deviceState = (type) => {
//   return devices.value
//     .filter((d) => d.type === type)
//     .some((d) => d.item.onoff === PowerState.On);
// };

// const onPowerClicked = (e) => {
//   if (zone.value.isEditMode) {
//     emitter.emit('showPopAlert', {
//       description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
//     });
//     return;
//   }
//   let onoff = getPower.value ? 0 : 1;
//   emit('onPowerClicked', { id: props.roomId, onoff: onoff });
// };

const onLightPowerClicked = (params) => {
  // if (mouseDragged) {
  //   return;
  // }
  // if (zone.value.isEditMode) {
  //   emitter.emit('showPopAlert', {
  //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   });
  //   return;
  // }
  // emit('onPowerClicked', {
  //   id: props.roomId,
  //   type: 1,
  //   onoff: getPower.value ? PowerState.Off : PowerState.On,
  // });
  emit('onPowerClicked', { ...params, groupId: props.roomId });
};

const onBoilerPowerClicked = (params) => {
  // if (zone.value.isEditMode) {
  //   emitter.emit('showPopAlert', {
  //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   });
  //   return;
  // }
  // emit('onPowerClicked', {
  //   id: props.roomId,
  //   type: 2,
  //   onoff: getPower.value ? PowerState.Off : PowerState.On,
  // });
  emit('onPowerClicked', { ...params, groupId: props.roomId });
};

const onCurtainPowerClicked = (params) => {
  // if (zone.value.isEditMode) {
  //   emitter.emit('showPopAlert', {
  //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   });
  //   return;
  // }
  // emit('onPowerClicked', {
  //   id: props.roomId,
  //   type: 3,
  //   onoff: getCurtainPower.value ? PowerState.Off : PowerState.On,
  // });
  emit('onPowerClicked', { ...params, groupId: props.roomId });
};

const onVentilatorPowerClicked = (params) => {
  // if (zone.value.isEditMode) {
  //   emitter.emit('showPopAlert', {
  //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   });
  //   return;
  // }
  // emit('onPowerClicked', {
  //   id: props.roomId,
  //   type: 4,
  //   onoff: getVentilatorPower.value ? PowerState.Off : PowerState.On,
  // });
  emit('onPowerClicked', { ...params, groupId: props.roomId });
};

// const onLightInfoClicked = (e) => {
//   if (zone.value.isEditMode) {
//     emitter.emit('showPopAlert', {
//       description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
//     });
//     return;
//   }
//   lightItemModal.show();
// }

// const onBoilerInfoClicked = (e) => {
//   if (zone.value.isEditMode) {
//     emitter.emit('showPopAlert', {
//       description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
//     });
//     return;
//   }
//   boilerItemModal.show();
// }

// const onRoomsInfoClick = () => {
//   store.commit('home/setCurrentGroup', props.roomId);
//   router.push(`/rooms/${props.roomId}`);
// };

// const onEditGroupClick = () => {
//   roomItemModal.show();
// };

const onUpdateRoomName = (value) => {
  requestUpdateRoom({
    name: value,
  });
  roomItemModal.hide();
};

const requestUpdateRoom = async (params) => {
  try {
    const result = await Axios.patch(
      GroupApi.UPDATE_GROUP.replace(':id', group.value._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateGroup', {
        id: props.roomId,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 정보 수정에 실패했습니다.',
    });
  }
};
</script>