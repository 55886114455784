const options = {
  state: {
    appThemeClass: '',
    appCoverClass: '',
    appBoxedLayout: false,
    appHeaderHide: false,
    appHeaderSearchToggled: false,
    appSidebarCollapsed: false,
    appSidebarMobileToggled: false,
    appSidebarMobileClosed: false,
    appSidebarHide: false,
    appContentFullHeight: true,
    appContentClass: '',
    appFooter: true,
    appFooterFixed: true,
    appThemePanelToggled: false,
    appSidebarMinified: false,
    isLoading: false
  },
  getters: {
    appThemeClass: (state) => state.appThemeClass,
    appCoverClass: (state) => state.appCoverClass,
    appBoxedLayout: (state) => state.appBoxedLayout,
    appHeaderHide: (state) => state.appHeaderHide,
    appHeaderSearchToggled: (state) => state.appHeaderSearchToggled,
    appSidebarCollapsed: (state) => state.appSidebarCollapsed,
    appSidebarMobileToggled: (state) => state.appSidebarMobileToggled,
    appSidebarMobileClosed: (state) => state.appSidebarMobileClosed,
    appSidebarHide: (state) => state.appSidebarHide,
    appContentFullHeight: (state) => state.appContentFullHeight,
    appContentClass: (state) => state.appContentClass,
    appFooter: (state) => state.appFooter,
    appFooterFixed: (state) => state.appFooterFixed,
    appThemePanelToggled: (state) => state.appThemePanelToggled,
    appSidebarMinified: (state) => state.appSidebarMinified,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setAppThemeClass: (state, payload) => (state.appThemeClass = payload),
    setAppCoverClass: (state, payload) => (state.appCoverClass = payload),
    setAppBoxedLayout: (state, payload) => (state.appBoxedLayout = payload),
    setAppHeaderHide: (state, payload) => (state.appHeaderHide = payload),
    setAppHeaderSearchToggled: (state, payload) =>
      (state.appHeaderSearchToggled = payload),
    setAppSidebarCollapsed: (state, payload) =>
      (state.appSidebarCollapsed = payload),
    setAppSidebarMobileToggled: (state, payload) =>
      (state.appSidebarMobileToggled = payload),
    setAppSidebarMobileClosed: (state, payload) =>
      (state.appSidebarMobileClosed = payload),
    setAppSidebarHide: (state, payload) => (state.appSidebarHide = payload),
    setAppContentFullHeight: (state, payload) =>
      (state.appContentFullHeight = payload),
    setAppContentClass: (state, payload) => (state.appContentClass = payload),
    setAppFooter: (state, payload) => (state.appFooter = payload),
    setAppFooterFixed: (state, payload) => (state.appFooterFixed = payload),
    setAppThemePanelToggled: (state, payload) =>
      (state.appThemePanelToggled = payload),
    setAppSidebarMinified: (state, payload) =>
      (state.appSidebarMinified = payload),
    setIsLoading: (state, payload) => (state.isLoading = payload),
  },
  actions: {},
};

export default options;
