<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">가상버튼 수정</h4>
    <p>
      가상버튼을 수정해주세요. 아래 장치들의 <code style="font-size: 100%">상태값</code>을 설정하고 
      <code style="font-size: 100%">수정 버튼</code>을 클릭하면 설정된 상태값으로 가상버튼이 수정됩니다.
    </p>

    <div class="flex-fill mb-1" v-if="targetDevices.length > 0">
      <div class="position-relative d-flex align-items-center">
          <div class="menu-search-input flex-fill" style="padding-right: 10px">
            <select class="form-control" v-model="deviceName" disabled>
              <option value="" style="display: none">장치이름을 선택해주세요.</option>
              <option v-for="index in 99" :key="index" :value="`${index}번`" disabled>{{ index }}번</option>
            </select>
          </div>          
          <button class="btn btn-primary" @click="onUpdateClick">수정</button>
      </div>
    </div>

    <hr class="mb-3" v-if="targetDevices.length > 0" />

    <div class="row">
      <VirtualDeviceItem
        v-for="item in targetDevices"
        :key="item._id"
        :group="group"
        :device="item"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  watch,
  nextTick
} from 'vue';
import Axios, { DeviceApi, VirtualDeviceApi } from '@/api';
import {
  useRouter,
  useRoute,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';
import { useStore } from 'vuex';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import VirtualDeviceItem from './components/VirtualDeviceItem.vue';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const store = useStore();
const deviceName = ref('');
const virtualDevices = ref([]);
const srcDevices = ref([]);
let rollbackStatus = true;

const zones = computed(() => {
  return store.getters['home/getZones'];
});

const groups = computed(() => {
  const groups = [];
  zones?.value?.forEach((zone) => groups.push(...zone.groups));
  return groups;
});

const group = computed(() => {
  return groups?.value?.find((g) => g._id == route.params.groupId);
});

const device = computed(() => {
  return group?.value?.devices?.find((d) => d._id == route.params.virtualId);
});

const realDevices = computed(() => {
  return group?.value?.devices?.filter(item => item.isVirtual === undefined || item.isVirtual === false) || [];
});

const targetDevices = computed(() => {
  return realDevices?.value?.filter((d => virtualDevices.value.findIndex(v => v._id === d._id) >= 0)) || [];
});

const publishStatus = (devices) => {
  devices?.forEach(device => {
    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: device.type,
      data: device.item,
    });
  });
};

const requestVirtualButton = async () => {
  try {
    const result = await Axios.get(VirtualDeviceApi.VIRTUAL_DEVICE.replace(':id', route.params.virtualId));
    if (result.resultCode === '0000') {
      virtualDevices.value = result.resultData.items;
      nextTick(() => {
        srcDevices.value = JSON.parse(JSON.stringify(targetDevices.value));
        deviceName.value = device.value.name || "";
        publishStatus(virtualDevices.value);
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
      okCallback: () => router.go(-1),
      cancelCallback:() => router.go(-1)
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '가상버튼 조회 중 오류가 발생하였습니다.',
      okCallback: () => router.go(-1),
      cancelCallback:() => router.go(-1)
    });
  }
};

onMounted(() => {
  requestVirtualButton();
});

onBeforeUnmount(() => {
  if (rollbackStatus) {
    publishStatus(srcDevices.value);
  }
});

const onUpdateClick = () => {
  const params = {
    items: targetDevices.value
  };
  requestUpdateDevice(params);
};

const requestUpdateDevice = async (payload) => {
  try {
    const result = await Axios.patch(
      VirtualDeviceApi.UPDATE_VIRTUAL_DEVICE.replace(':id', route.params.virtualId),
      payload,
    );
    if (result.resultCode === '0000') {
      rollbackStatus = !(device.value.isVirtual && device.value.item.onoff);
      emitter.emit('showPopAlert', {
        description: '가상 버튼이 수정되었습니다.',
        okCallback: () => router.go(-1),
        cancelCallback:() => router.go(-1)
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '가상버튼 수정 중 오류가 발생하였습니다.',
    });
  }
}; 
</script>
