<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">비밀번호 변경</h1>
      <div class="text-muted text-center mb-4">
        새로운 비밀번호를 입력해주세요.
      </div>
      <form>
        <div class="mb-3">
          <label class="form-label"
            >비밀번호 <span class="text-danger">*</span></label
          >
          <input
            autoComplete="off"
            type="password"
            class="form-control form-control-lg fs-15px"
            :class="validCheck ? (isValidPassword ? '' : 'is-invalid') : ''"
            placeholder="비밀번호를 입력해주세요."
            v-model="password"
          />
          <div class="invalid-feedback" v-if="validCheck && !isValidPassword">
            비밀번호를 입력해주세요.
          </div>
        </div>
        <div class="mb-4">
          <label class="form-label"
            >비밀번호 확인 <span class="text-danger">*</span></label
          >
          <input
            autoComplete="off"
            type="password"
            class="form-control form-control-lg fs-15px"
            :class="
              validCheck
                ? isValidPasswordConfirm && isSamePassword
                  ? ''
                  : 'is-invalid'
                : ''
            "
            placeholder="비밀번호를 한번 더 입력해주세요."
            v-model="passwordConfirm"
          />
          <div
            class="invalid-feedback"
            v-if="validCheck && !isValidPasswordConfirm"
          >
            비밀번호를 한번 더 입력해주세요.
          </div>
          <div
            class="invalid-feedback"
            v-else-if="validCheck && !isSamePassword"
          >
            비밀번호가 일치하지 않습니다.
          </div>
        </div>
      </form>
      <button
        type="submit"
        class="btn btn-primary btn-lg d-block w-100 fw-500 mb-4"
        @click="onChangeClick"
      >
        비밀번호 변경
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';
import Axios, { AuthApi } from '@/api';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;

const password = ref('');
const passwordConfirm = ref('');
const validCheck = ref(false);
const isValidPassword = computed(() => password.value?.length > 0);
const isValidPasswordConfirm = computed(
  () => passwordConfirm.value?.length > 0,
);
const isSamePassword = computed(() => password.value === passwordConfirm.value);
const isValidChange = computed(
  () => isValidPassword.value && isValidPasswordConfirm.value && isSamePassword.value
);

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  if (!route.query?.certKey || !route.query?.id) {
    router.push("/login");
    return;
  }
  hideLayoutWidgets();
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

const requestChangePassword = async (params) => {
  Axios.patch(AuthApi.CHANGE_PASSWORD, params)
    .then((res) => {
      console.log(res);
      if (res.resultCode == "0000") {
        router.push("/change/password/complete");
      } else {
        emitter.emit('showPopAlert', {
          description: res.resultMessage,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      emitter.emit('showPopAlert', {
        description: "비밀번호 변경에 실패했습니다.",
      });
    });
};

const onChangeClick = async () => {
  validCheck.value = true;
  if (isValidChange.value) {
    requestChangePassword({
      id: route.query.id,
      certKey: route.query.certKey,
      password: password.value
    });
  }
};
</script>
