<template>
  <div class="col" style="padding-right: 2px;">
    <div class="list-group" ref="lightItem" style="border-radius: 1.2rem;" @click="onLightPowerClicked" v-touch:press="onTouchDown" v-touch:drag="onTouchDrag" v-touch:release="onTouchRelease" v-touch:hold="onTouchHold">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background: ${deviceState(1) ? `linear-gradient(to right, #ffe082 ${percent}%, #fff8e1 ${percent}%)` : 'inherits'};`">
        <i class="fa-solid fa-lightbulb me-2" />
        <div class="flex-fill" style="font-size: 0.8rem">
          {{ props.roomName }} 조명
          <br>
          {{ deviceState(1) ? currentBrightText : "" }}
        </div>
        <!-- <div class="mx-1" @click.stop="onLightInfoClicked">
          <i class="fa fa-ellipsis" />
        </div> -->
        <div class="mx-1" v-if="lightDevices.length > 0" @click.stop="onExpandClicked">
          <i :class="`fa ${isExpand ? 'fa-chevron-up' : 'fa-chevron-down'}`" style="font-size: 0.8rem" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isExpand">
        <RoomLightDeviceItem v-for="device in realDevices" :key="device._id" :roomName="props.roomName" :roomId="props.roomId" :deviceId="device._id" />
        <RoomLightDeviceItem v-for="device in virtualDevices" :key="device._id" :roomName="props.roomName" :roomId="props.roomId" :deviceId="device._id" />
      </div>
    </transition>
    <!-- <div class="list-group" ref="lightItem" style="border-radius: 1.2rem;" v-else @click="onLightPowerClicked" @mousedown="onMouseDown" v-touch:press="onTouchDown">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background: ${deviceState(1) ? `linear-gradient(to right, #ffe082 ${percent}%, #fff8e1 ${percent}%)` : 'inherits'};`">
        <div class="flex-fill" style="font-size: 0.8rem">
          <i class="fa-solid fa-lightbulb me-2" />
          {{ props.roomName }} 조명 {{ deviceState(1) ? currentBrightText : "" }}
        </div>
        <div class="ms-3" @click.stop="onLightInfoClicked">
          <i class="fa fa-chevron-right" style="font-size: 0.8rem" />
        </div>
      </div>
    </div> -->
  </div>
        
  <LightItemModal
    v-if="lightItemModal.visible"
    :visible="lightItemModal.visible"
    :cancelCallback="lightItemModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
    :handlePowerClick="onLightPowerClicked"
  />
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import LightItemModal from './LightItemModal.vue';
import useModal from '@/common/hooks/useModal';
import useMqttControl from '@/common/hooks/useMqttControl';
import useDeviceTouch from '@/common/hooks/useDeviceTouch';
import RoomLightDeviceItem from './RoomLightDeviceItem.vue';

const mqttControl = reactive(useMqttControl());
const { emit } = getCurrentInstance();
const props = defineProps(['roomId', 'roomName']);
const store = useStore();
const lightItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const lightItem = ref(null);
const percent = ref(0.0);
const currentBright = ref(0);
const isExpand = ref(false);
const { 
  handlePower, 
  handleTouchDown, 
  handleTouchDrag, 
  handleTouchRelease,
  handleTouchHold
} = useDeviceTouch(props.roomId);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});

const lightDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 1,
  );
});
const realDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 1 && !d.isVirtual,
  );
});
const virtualDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 1 && d.isVirtual,
  );
});
const lightBright = computed(() => {
  if (realDevices.value.length === 0) {
    return 0;
  }

  let bright = 0;
  realDevices.value.forEach((device) => {
    if (device.item?.bright > bright && device.item?.onoff) {
      bright = device.item?.bright;
    }
  });
  return bright;
});
const lightColor = computed(() => {
  if (realDevices.value.length === 0) {
    return 0;
  }

  let color = 0;
  realDevices.value.forEach((device) => {
    if (device.item?.color > color && device.item?.onoff) {
      color = device.item?.color;
    }
  });
  return color;
});

const getPower = computed(() => {
  return realDevices.value.some((d) => d.item?.onoff === PowerState.On);
});

const currentBrightText = computed(() => {
  return `${parseInt(lightBright.value)}%`;
})

watch(lightBright, () => {
  currentBright.value = lightBright.value;
  percent.value = (lightBright.value - 10) / 90 * 100;
})

onMounted(() => {
  currentBright.value = lightBright.value;
  percent.value = (currentBright.value - 10) / 90 * 100;
});

const deviceState = (type) => {
  return realDevices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.onoff === PowerState.On);
};

const onLightPowerClicked = (e) => {
  handlePower(() => {
    if (zone.value.isEditMode) {
      emitter.emit('showPopAlert', {
        description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
      });
      return;
    }
    emit('onPowerClicked', {
      id: props.roomId,
      type: 1,
      onoff: getPower.value ? PowerState.Off : PowerState.On,
    });
  });
};

const onLightInfoClicked = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  lightItemModal.show();
};

const onExpandClicked = (e) => {
  isExpand.value = !isExpand.value;
}

const brightChange = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  realDevices.value.filter(device => !device.isVirtual).forEach((device) => {
    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: 1,
      data: {
        ports: device.item?.ports?.map((p) => p),
        onoff: device.item?.onoff,
        bright: parseInt(currentBright.value),
        color: device.item?.color,
      },
    });
  })
};

const onTouchDown = (e) => {
  if (deviceState(1)) {
    handleTouchDown(e);
  }
}

const onTouchDrag = (e) => {
  handleTouchDrag(e, (screenX, startX) => {
    percent.value = percent.value + (screenX - startX) / lightItem.value.clientWidth * 100.0;
    percent.value = percent.value <= 0 ? 0 : percent.value;
    percent.value = percent.value >= 100 ? 100 : percent.value;
    currentBright.value = parseInt((9 * percent.value * 0.01) + 1) * 10;
  });
}

const onTouchRelease = (e) => {
  handleTouchRelease(e, brightChange);
}

const onTouchHold = (e) => {
  handleTouchHold(e, onLightInfoClicked);
}
</script>