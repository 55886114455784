<template>
  <WizardZoneSelectStep
    v-if="wizardStep === 1"
    :step="wizardStep"
    :zones="zones"
    :selectedZone="selectedZone"
    :handleChange="onSelectZoneChange"
  />
  <WizardConnectStep v-if="wizardStep === 2" :step="wizardStep" />
  <WizardSettingStep
    v-if="wizardStep === 3"
    :step="wizardStep"
    :controller="connectedController"
    :ssid="settingParams.ssid"
    :password="settingParams.pwd"
    :handleSsidChange="onSsidChange"
    :handlePasswordChange="onPasswordChange"
  />
  <WizardSetWifiCompleteStep
    v-if="wizardStep === 4"
    :step="wizardStep"
    :controller="connectedController"
  />

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onNextStepClick"
          >
            {{
              wizardStep === 1
                ? '선택'
                : wizardStep === 2
                ? '연결'
                : wizardStep === 3
                ? '설정'
                : '완료'
            }}
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import WizardZoneSelectStep from './components/wizard/WizardZoneSelectStep';
import WizardConnectStep from './components/wizard/WizardConnectStep';
import WizardSettingStep from './components/wizard/WizardSettingStep';
import WizardSetWifiCompleteStep from './components/wizard/WizardSetWifiCompleteStep';
import { SettingConfig } from '@/common/constants';

const store = useStore();
const router = useRouter();
const {
  getWifiInfo,
  connectWifi,
  settingController,
  requestRegistController,
  requestTransferController,
  disConnectWifi
} = useMobile();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
const zones = computed(() => store.getters['home/getZones']);
const account = computed(() => store.getters['account']);
const selectedZone = ref(undefined);
let requestCount = 0;
let isSettingController = false;

const settingParams = ref({
  ssid: '',
  pwd: '',
  firmware_version: '',
  firmware_url: '',
  uuid: '',
  device_key: '',
  mqtt_url: process.env.VUE_APP_CTRL_MQTT_DOMAIN,
  mqtt_username: '',
  mqtt_password: '',
});

const connectedController = ref({
  _id: '',
  version: '',
  model: '',
});
const wizardStep = ref(1);

onBeforeUnmount(() => {
  disConnectWifi();
});

const showPopAlert = (msg) => {
  emitter.emit('showPopAlert', {
    description: msg,
  });
};

const onSsidChange = (value) => {
  settingParams.value.ssid = value;
};

const onPasswordChange = (value) => {
  settingParams.value.pwd = value;
};

const onSelectZoneChange = (e) => {
  const zone = zones.value.find((zone) => zone._id == e.target.value);
  selectedZone.value = zone;
};

const onNextStepClick = async () => {
  try {
    if (wizardStep.value === 1) {
      if (!selectedZone.value) {
        showPopAlert('컨트롤러를 등록할 영역을 선택해주새요.');
        return;
      }
    } else if (wizardStep.value === 2) {
      const currentWifi = await getWifiInfo();
      // settingParams.value.ssid = currentWifi.ssid;

      const controllerInfo = await connectWifi();
      connectedController.value.model = controllerInfo.model;
      connectedController.value._id = controllerInfo.serial_no;
      connectedController.value.version = controllerInfo.version;
    } else if (wizardStep.value === 3) {
      if (!isSettingController) {
        if (settingParams.value.ssid.length === 0) {
          showPopAlert('SSID를 입력해주세요.');
          return;
        }
        if (settingParams.value.pwd.length === 0) {
          showPopAlert('비밀번호를 입력해주세요.');
          return;
        }
        await settingController(
          settingParams.value,
          connectedController.value._id,
        );
        isSettingController = true;
      }

      // await listenChangedNetworkInfo();

      const request = () => {
        return new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await requestRegistController(
                connectedController.value,
                selectedZone.value._id,
              );
              resolve(result);
            } catch (e) {
              reject(e);
            }
          }, SettingConfig.requestInterval);
        });
      };

      emitter.emit('showLoading');
      let result = undefined;
      for (let i = 0; i < SettingConfig.requestCount; i++) {
        result = await request();
        if (result?.resultCode === "0000") {
          await store.dispatch('home/fetchAllZones');

          emitter.emit('hideLoading');
          wizardStep.value += 1;
          return;
        }
        if (result?.resultCode === "1001") {
          emitter.emit('hideLoading');
          emitter.emit('showPopConfirm', {
            title: "등록된 컨트롤러",
            description: "이미 등록된 컨트롤러입니다.\n이관 받으시겠습니까?",
            okCallback: async () => {
              const transResult = await requestTransferController(
                connectedController.value,
                account.value._id,
                result.resultData._id,
                selectedZone.value._id
              );

              if (transResult?.resultCode === "0000") {
                wizardStep.value += 1;
                // emitter.emit("showPopAlert", {
                //   description: '이관이 완료되었습니다.',
                //   okCallback: () => router.go(-1),
                //   cancelCallback: () => router.go(-1),
                // });
              } else {
                emitter.emit("showPopAlert", {
                  description: '이관에 실패했습니다.'
                });
              }
            }
          });

          return;
        }
      }
 
      showPopAlert(result?.resultMessage?.length > 0 ? result.resultMessage : "컨트롤러 등록에 실패했습니다.");
      return;
    } else if (wizardStep.value === 4) {
      router.go(-1);
      return;
    }

    wizardStep.value += 1;
  } catch (err) {
    console.log(err);
    if (err?.length > 0) {
      showPopAlert(err);
    }
  }
};
</script>
