<template>
  <div class="error-page">
    <div class="error-page-content">
      <div class="error-img">
        <div class="error-img-code">404</div>
        <img :src="require('@/assets/images/template/page/404.svg')" alt="" />
      </div>

      <h1>잘못된 페이지</h1>
      <h3>해당 페이지를 찾을 수 없습니다.</h3>
      <p class="text-muted mb-2">여기에 있는 링크로 이동해주세요.</p>
      <p class="mb-4">
        <RouterLink to="/rooms" class="text-decoration-none"
          >집 목록</RouterLink
        >
        <span class="link-divider"></span>
        <RouterLink to="/devices" class="text-decoration-none"
          >장치 목록</RouterLink
        >
        <span class="link-divider"></span>
        <RouterLink to="/schedules" class="text-decoration-none"
          >스케줄 관리</RouterLink
        >
        <span class="link-divider"></span>
        <RouterLink to="/profile" class="text-decoration-none"
          >프로필</RouterLink
        >
        <span class="link-divider"></span>
        <RouterLink to="/share" class="text-decoration-none">공유</RouterLink>
        <span class="link-divider"></span>
        <RouterLink to="/info" class="text-decoration-none">정보</RouterLink>
      </p>
      <RouterLink to="/rooms" class="btn btn-primary"
        >메인 화면으로 이동</RouterLink
      >
    </div>
  </div>
</template>

<script setup></script>
