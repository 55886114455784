<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break" v-touch:hold="onTouchHold">
          <div style="font-size: 1rem">{{ props.data.name }}</div>
        </div>
        <div @click.stop="onDeleteClick">
          <i class="fa-solid fa-x"></i>
        </div>
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        <div class="flex-fill" @click="onDetailClick">
          <div
            class="fw-400 text-green text-end me-2"
            style="font-size: 0.8rem"
          >
            상세보기
          </div>
        </div>
        <div>
          <i
            class="fa fa-chevron-right text-green"
            style="font-size: 0.8rem"
          ></i>
        </div>
      </ListGroupItem>
    </div>
    <RoomItemModal
      v-if="groupModal.visible"
      :visible="groupModal.visible"
      :okCallback="onUpdateGroupName"
      :cancelCallback="groupModal.hide"
      :roomName="props.data?.name || ''"
    />
  </div>
</template>

<script setup>
import { onMounted, defineProps, getCurrentInstance, computed, reactive } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import RoomItemModal from '@/views/rooms/components/RoomItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { GroupApi } from '@/api';

const { emit } = getCurrentInstance();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const props = defineProps(['index', 'data', 'onGroupUpdated']);
const store = useStore();
const groupModal = reactive(useModal());
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});

onMounted(() => {
  console.log('ZoneItem.onMounted().props =>', props);
});

const onDetailClick = () => {
  console.log('ZoneItem.onDetailClick');
  // store.commit("home/setCurrentGroup",props.roomId )

  router.push(
    `/settings/zones/${route.params.zoneId}/groups/${props.data._id}/devices`,
  );
};

const onDeleteClick = () => {
  console.log('GroupItem.onDeleteClick =>', {
    name: props.data.name,
    groupId: props.data._id,
  });
  emit('onDeleteClick', { name: props.data.name, groupId: props.data._id });
};

const onTouchHold = () => {
  groupModal.show();
}

const onUpdateGroupName = (value) => {
  requestUpdateGroup({
    name: value,
  });
  groupModal.hide();
};

const requestUpdateGroup = async (params) => {
  try {
    const result = await Axios.patch(
      GroupApi.UPDATE_GROUP.replace(':id', props.data._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateGroup', {
        id: props.data._id,
        data: params,
      });
      emit('onGroupUpdated');
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 정보 수정에 실패했습니다.',
    });
  }
};
</script>
