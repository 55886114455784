<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">업데이트</h4>
    <p v-if="controllers?.length > 0">
      <code style="font-size: 100%"
        >펌웨어 업데이트가 가능한 장치가 {{ controllers?.length || 0 }}개
        있습니다.</code
      >
      펌웨어 업데이트는 약 2~3분정도 걸립니다.
    </p>
    <p v-else>모든 장치의 펌웨어가 최신 버전입니다.</p>

    <hr class="mb-4" v-if="allControllers?.length > 0" />

    <div class="row" v-if="allControllers?.length > 0">
      <UpdateControllerItem
        v-for="(item, index) in allControllers"
        :key="item._id"
        :index="index"
        :data="item"
      />
      <div :class="`col ${controllers?.length > 0 ? 'mt-2' : ''}`">
        <button
          type="button"
          class="btn btn-primary"
          style="--bs-btn-padding-y: 0.16rem; width: 100%;"
          v-if="controllers?.length > 0"
          @click="onFirmwareUpdateClick"
        >
          펌웨어 업데이트
        </button>
      </div>
    </div>
  </div>
  <PopProgress :percent="percent" :visible="visibleProgress" />
</template>

<script setup>
import Axios, { CtrlApi } from '@/api';
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopProgress from '@/common/components/popup/PopProgress.vue';
import UpdateControllerItem from "./components/UpdateControllerItem.vue"

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const getters = computed(() => store.getters);
const allControllers = computed(
  () => getters.value['home/getControllers'],
);
const controllers = computed(
  () => getters.value['home/getLowVersionControllers'],
);
const updatingControllers = computed(
  () => getters.value['home/getFirmwareUpdatingControllers'],
);
const updatedControllers = computed(
  () => getters.value['home/getFirmwareUpdatedControllers'],
);
const percent = ref(0);
const visibleProgress = ref(false);
const timeout = 60; // second
let currentSecond = 0;
let timerId = undefined;
let firmwareUpdateCount = 0;

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting', {
    title: '펌웨어 업데이트',
    isShowZone: false,
  });
});
onBeforeUnmount(() => {
  emitter.emit('onChangeHeaderSetting', { title: '', isShowZone: true });
});

const onFirmwareUpdateClick = () => {
  controllers.value.forEach((item) => {
    emitter.emit('showLoading');
    console.log({
      serial: item._id,
      cmd: 'f',
      type: 1,
      new_version: item.lastVersion,
      firmware_url: process.env.VUE_APP_PROXY_FIRMWARE_DOMAIN + `/api/${item.downloadUrl}`,
    })
    mqttControl.onPublishFirmware(item._id, {
      serial: item._id,
      cmd: 'f',
      type: 1,
      new_version: item.lastVersion,
      firmware_url: process.env.VUE_APP_PROXY_FIRMWARE_DOMAIN + `/api/${item.downloadUrl}`,
    });

    setTimeout(() => {
      if (!visibleProgress.value) {
        emitter.emit('hideLoading', true);
        emitter.emit('showPopAlert', {
          description: '펌웨어 업데이트에 실패 했습니다.',
        });
      }
    }, 3000);
  });
};

const requestVersionUpdate = (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(CtrlApi.UPDATE_CTRL.replace(":id", id), params);
      if (result.resultCode === '0000') {
        resolve(true);
      } else {
        console.warn(result.resultMessage);
        reject(result.resultMessage);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

watch(updatingControllers, () => {
  if (updatingControllers.value.length > firmwareUpdateCount) {
    firmwareUpdateCount = updatingControllers.value.length;
  }

  if (updatingControllers.value.length > 0 && !visibleProgress.value) {
    emitter.emit('hideLoading', true);
    percent.value = 0;
    visibleProgress.value = true;
    currentSecond = 0;

    timerId = setInterval(() => {
      currentSecond += timeout / 1000;
      percent.value = parseInt((currentSecond / timeout) * 100);
      // console.log(currentSecond / timeout);
      if (percent.value >= 100) {
        percent.value = 100;
        clearInterval(timerId);
        firmwareUpdateCount = 0;

        setTimeout(() => {
          visibleProgress.value = false;
          emitter.emit('showPopAlert', {
            description: '펌웨어 업데이트에 실패 했습니다.',
          });
        }, 500);
      }
    }, (timeout * 1000) / 1000);
  }
});

watch(updatedControllers, () => {
  if (
    firmwareUpdateCount > 0 &&
    updatedControllers.value.length === firmwareUpdateCount
  ) {
    percent.value = 100;
    clearInterval(timerId);
    firmwareUpdateCount = 0;

    setTimeout(async () => {
      visibleProgress.value = false;
      console.log(updatedControllers.value);
      console.log(controllers.value);
      
      updatedControllers.value.forEach(async (item) => {
        const foundItem = controllers.value.find(ctrl => ctrl._id === item._id);
        if (foundItem) {
          await requestVersionUpdate(item._id, {
            version: foundItem.lastVersion
          });
        }
      });
      await store.dispatch('home/fetchAllZones');
      emitter.emit('showPopAlert', {
        description: '펌웨어 업데이트가 완료 되었습니다.',
      });
    }, 500);
  }
});
</script>
