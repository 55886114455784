<template>
  <LightItem
    v-if="props.type === 1"
    :groupId='props.groupId'
    :deviceId="props.deviceId"
    :name="props?.name"
    v-on:onPowerClick="onPowerClick"
  />
  <BoilerItem
    v-else-if="props.type === 2"
    :groupId='props.groupId'
    :deviceId="props.deviceId"
    :name="props?.name"
    v-on:onPowerClick="onPowerClick"
  />
  <CurtainItem
    v-else-if="props.type === 3"
    :groupId='props.groupId'
    :deviceId="props.deviceId"
    :name="props?.name"
    v-on:onPowerClick="onPowerClick"
  />
  <ventilator-item
    v-else-if="props.type === 4"
    :groupId='props.groupId'
    :deviceId="props.deviceId"
    :name="props?.name"
    v-on:onPowerClick="onPowerClick"
  />
</template>

<script setup>
import { defineProps, reactive, computed, onMounted ,getCurrentInstance} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import LightItem from './LightItem.vue';
import BoilerItem from './BoilerItem.vue';
import CurtainItem from './CurtainItem.vue';
import VentilatorItem from './VentilatorItem.vue';
import useMqttControl from '@/common/hooks/useMqttControl';
const mqttControl = reactive(useMqttControl());
const router = useRouter();
const route = useRoute();
const store = useStore();
const props = defineProps(['type', 'name', 'groupId', 'deviceId', 'device']);
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

onMounted(() => {
  console.log('DeviceItem.onMounted().props => ', props);
});
const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const device = computed(() => {
  return store.getters['home/getCurrentGroup'].devices.find(
    (d) => d._id === props.deviceId,
  );
});
/**
 * 모든 디바이스의 공통적인 Power On/Off만 공통으로 처리 하고 각 디바이스별 컨트롤은 개별 처리함.
 */
const onPowerClick = (payload) => {
  console.log('DeviceItem.onPowerClick => payload', payload);

  if(zone.value.isEditMode){
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  if (device.value.type == 1) {
    var data = {
      ports: device.value.item.ports.map((p) => p),
      onoff: payload.onoff,
      bright: device.value.item.bright,
      color: device.value.item.color,
    };
  } else {
    var data = { serial: device.value.item.serial, ...payload };
  }
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: device.value.type,
    data: data,
  });
};
</script>
