import { ref, computed } from 'vue';
import { useStore } from 'vuex';
// import { reactive, defineProps, getCurrentInstance, computed, onMounted, onUnmounted, ref } from 'vue';
import { PowerState } from '@/common/constants';

export default function useDeviceTouch(roomId) {
  const store = useStore();
  let mousePressed = false;
  let mouseDragged = false;
  let startX = 0.0;

  const zone = computed(() => {
    if (store.state.home.zones.length > 0) {
      return store.getters['home/getCurrentZone'];
    } else return null;
  });
  const group = computed(() => {
    return zone.value.groups.find((g) => g._id === roomId);
  });
  const devices = computed(() => {
    return group.value ? group.value.devices : undefined;
  });

  const deviceState = (type) => {
    return devices.value
      .filter((d) => d.type === type)
      .some((d) => d.item.onoff === PowerState.On);
  };

  const handlePower = (handleClick) => {
    if (mouseDragged) {
      return;
    }
    handleClick();
  };

  const handleTouchDown = (e) => {
    mousePressed = true;
    mouseDragged = false;
    const screenX = e.targetTouches ? e.targetTouches[0]?.screenX : e.screenX;
    startX = screenX;
  };
  
  const handleTouchDrag = (e, handlePercentChange) => {
    if (mousePressed) {
      mouseDragged = true;
      const screenX = e.targetTouches ? e.targetTouches[0]?.screenX : e.screenX;
      handlePercentChange(screenX, startX);
      startX = screenX;
    }
  };
  
  const handleTouchRelease = (e, handleChange) => {
    if (mouseDragged) {
      handleChange();
    }
    mousePressed = false;
  };

  const handleTouchHold = (e, handleInfo) => {
    handleInfo();
  };

  return {
    handlePower,
    handleTouchDown,
    handleTouchDrag,
    handleTouchRelease,
    handleTouchHold
  };
}
