<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">로그인</h1>
      <div class="text-muted text-center mb-4">로그인 중 입니다.</div>
      <div class="text-center">
        <!-- <div class="text-primary spinner-border" /> -->
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getCurrentInstance } from 'vue';
import { requestGoogleToken, requestGoogleUser } from '@/common/auth/google';

const store = useStore();
const router = useRouter();
const params = new URL(document.location.toString()).searchParams;
const authCode = params.get('code');
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(async () => {
  if (authCode?.length === 0) {
    router.replace('/login');
  }

  hideLayoutWidgets();

  try {
    const token_res = await requestGoogleToken(authCode);
    console.log(token_res);
    const user_res = await requestGoogleUser(token_res.access_token);
    console.log(user_res);

    // if (!user_res.email || user_res.email?.length === 0) {
    //   throw '이메일 정보가 없습니다.';
    // }
    requestLogin({
      type: 'g',
      email: user_res.email || '',
      id: user_res.id,
    });
  } catch (error) {
    console.error(error);
    emitter.emit('showPopAlert', {
      description: '로그인에 실패했습니다.',
      okCallback: () => router.replace('/login'),
    });
  }
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

const requestLogin = async (params) => {
  try {
    const result = await store.dispatch('login', params);
    if (result.resultCode === '0000') {
      router.replace('/rooms');
      showLayoutWidgets();
    } else if (result.resultCode === '9002') {
      store.commit('setTempSnsInfo', params);
      emitter.emit('showPopAlert', {
        description: '가입되지 않은 회원입니다.\n회원가입 화면으로 이동합니다.',
        okCallback: () => router.replace('/join'),
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
        okCallback: () => router.replace('/login'),
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '로그인에 실패했습니다.',
      okCallback: () => router.replace('/login'),
    });
  }
};
</script>
