<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">회원가입 인증</h1>
      <div class="text-muted text-center mb-4">
        회원가입을 완료하기 위해서 이메일 인증을 해주세요.
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-lg d-block w-100 fw-500 mb-4"
        @click="onResendEmailClick"
      >
        인증메일 재전송
      </button>
      <div class="text-muted text-center">
        인증이 완료되었나요?
        <RouterLink to="/change/password">로그인</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';
import Axios, { AuthApi } from '@/api';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;
const account = computed(() => getters.value.account);

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  // console.log(JSON.stringify(account.value))
  hideLayoutWidgets();
});

onBeforeUnmount(() => {
  store.dispatch('logout');
  showLayoutWidgets();
});

const onResendEmailClick = () => {
  Axios.get(AuthApi.SEND_EMAIL, {
    id: account.value._id
  })
    .then((res) => {
      console.log(res);
      if (res.resultCode == "0000") {
        emitter.emit('showPopAlert', {
          description: "인증 메일이 전송되었습니다.\n이메일을 확인해주세요.",
          okCallback: () => router.push("/login"),
          cancelCallback: () => router.push("/login"),
        });
      } else {
        emitter.emit('showPopAlert', {
          description: res.resultMessage,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      emitter.emit('showPopAlert', {
        description: "인증 메일 재전송에 실패했습니다.",
      });
    });
};
</script>
