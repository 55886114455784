import { useMobileDetection } from "vue3-mobile-detection";
import { isMobile ,isIOS , isAndroid} from 'mobile-device-detect';
export default {
  install(App){
    const { isMobile } = useMobileDetection();
    const userAgent = navigator.userAgent.toLowerCase();
    // const logger = App.config.globalProperties.logger

    App.config.globalProperties.mobile = {

      showSnackBar : (payload) =>{
        console.log('call showSnackBar~'+payload)
        // if (userAgent.indexOf('android') !== -1) {
        if(isAndroid){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("showSnackBar", payload);}
        // } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        }else if(isIOS){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("showSnackBar", payload);}
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert(payload)
        }
      },
      /**
       * 현재 접속된 wifi 정보를 가져온다.
       * @param {*} payload { callback: 'onGetWifiResult' }
       */
      getWifiInfo : (payload) => {        
        console.log('call getWifiInfo~'+payload)
        // if (userAgent.indexOf('android') !== -1) {
        if(isAndroid){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("getWifiInfo", payload);}
        // } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        }else if(isIOS){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("getWifiInfo", payload);}
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
          return false;
        }
      },
      /**
       * 접속 가능한 wifi 목록을 조회한다
       * @param {*} payload 조회 필터값을 지정해준다. { prefix: 'inshow', callback: 'onSearchResult' }
       * @returns 
       */
      searchWifi : (payload) => {
        if(!isMobile){
          alert('모바일 환경에서만 제공됩니다.');
          return
        }
        console.log('call searchWifi~'+payload)
        if(isAndroid){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("searchWifi", payload);}
        } else if(isIOS){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("searchWifi", payload);}
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * wifi AP에 접속한다.
       * @param {*} payload wifi 접속 정보 전달
       * {
            ssid: ,
            password: ,
            bssid: ,
            callback: 'onConnectResult',
          }
       */
      connectWifi : (payload) =>{
        console.log('call connectWifi~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("connectWifi", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("connectWifi", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 접속된 iot 장치 정보를 조회 한다.
       * @param {*} payload { callback: 'onGetDeviceResult' }
       */
      getDeviceInfo : (payload) => {        
        console.log('call getDeviceInfo~'+payload)
        if(isAndroid){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("getDeviceInfo", payload);}
        } else if(isIOS){
          if(window.flutter_inappwebview){ window.flutter_inappwebview?.callHandler("getDeviceInfo", payload);}
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 접속된 iot 장치 설정 정보를 전송한다.
       * @param {*} payload 
       * {
            ssid: ,
            pwd: ,
            firmware_version: '',
            firmware_url: '',
            uuid: '',
            device_key: '',
            mqtt_url: ,
            mqtt_username: '',
            mqtt_password: '',
            callback: 'onSettingResult' }
       */
      settingDevice : (payload) =>{
        console.log('call settingDevice~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("settingDevice", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("settingDevice", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 접속된 iot 장치의 AP 연결을 종료한다.
       * @param {*} payload 
       * {
            ssid: ''  현재 접속된 wifi AP SSID
          }
       */
      disConnectWifi : (payload) =>{
        console.log('call disConnectWifi~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("disConnectWifi", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("disConnectWifi", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 모바일 단말의 세팅창을 연다.
       * @param {*} payload 
       */
      openSetting : (payload) => {
        console.log('call openSetting~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("openSetting", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("openSetting", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 로그인 정보를 저장한다.
       * @param {*} payload 
       */
      setLoginInfo : (payload) => {
        console.log('call setLoginInfo~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("setLoginInfo", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("setLoginInfo", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          // alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 모바일 단말의 세팅창을 연다.
       * @param {*} payload 
       */
      getLoginInfo : (payload) => {
        console.log('call getLoginInfo~' + payload)
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("getLoginInfo", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("getLoginInfo", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          // alert('모바일 환경에서만 제공됩니다.')
        }
      },

      /**
       * 로그인 정보를 삭제
       */
      logout : (payload) => {
        console.log('call logout~')
        if(isAndroid){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("logout", payload);
        } else if(isIOS){
          if(window.flutter_inappwebview) window.flutter_inappwebview?.callHandler("logout", payload);
        } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
          //  return window.opener.postMEssage(message);
          // alert('모바일 환경에서만 제공됩니다.')
        }
      }
    }
  }
}