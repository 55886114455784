<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">비밀번호 변경 완료</h1>
      <div class="text-muted text-center mb-4">
        비밀번호가 변경되었습니다.<br>새 비밀번호로 로그인 해주세요.
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  hideLayoutWidgets();
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});
</script>
