<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">일괄 점소등 설정</h4>
      <p class="mb-0">
        1. 일괄 점소등에 포함할 장치를
        <code style="font-size: 100%">체크박스</code>에 체크해주세요.
      </p>
      <p class="mb-0">
        2. 아래의
        <code style="font-size: 100%">변경 버튼</code>을 눌러서 등록해주세요.
      </p>
    </div>

    <hr class="mb-3" v-if="devices.length > 0" />

    <div class="row" v-if="devices.length > 0">
      <div class="col-xl-6 mb-0">
        <div class="text-muted fs-12px mb-2"><b>장치 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(device, index) in devices"
            :key="device._id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="device._id"
                :checked="getDeviceChecked(device._id)"
                @change="onSelectDeviceChange"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ device.name }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onBatchSettingClick"
          >
            변경
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  watch,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';
import Axios, { DeviceApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const router = useRouter();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
const mqttControl = reactive(useMqttControl());

const account = computed(() => store.getters['account']);
const zone = computed(() => store.getters['home/getCurrentZone']);
const devices = computed(() => {
  const result = [];
  zone.value?.groups.forEach((group) => result.push(...group.devices));
  return result.filter((device) => device.type == 1 && !device.isVirtual);
});
const selectedDeviceIds = ref([]);

watch(devices, () => {
  initSelectedDevices();
});

onMounted(() => {
  initSelectedDevices();
});

const initSelectedDevices = () => {
  selectedDeviceIds.value = devices.value
    .filter((device) => device.config.isAllOn)
    .map((device) => device._id);
};

const getDeviceChecked = (id) => {
  return selectedDeviceIds.value.findIndex((item) => item == id) >= 0;
};

const onSelectDeviceChange = (e) => {
  const targetIndex = selectedDeviceIds.value.findIndex(
    (item) => item == e.target.value,
  );
  if (targetIndex >= 0) {
    selectedDeviceIds.value = selectedDeviceIds.value.filter(
      (item) => item != e.target.value,
    );
  } else {
    selectedDeviceIds.value.push(e.target.value);
  }
};

const requestUpdateDevices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(DeviceApi.UPDATE_DEVICES, params);
      if (result.resultCode === '0000') {
        resolve(result);
        return;
      }

      reject(result.resultMessage);
    } catch (e) {
      console.log(e);
      reject('설정 중 오류가 발생하였습니다.');
    }
  });
};

const publishBatchOnOff = () => {
  const selectedDevices = selectedDeviceIds.value.map((item) =>
    devices.value.find((device) => device._id == item),
  );
  const selectedControllers = {};
  selectedDevices.forEach((device) => {
    if (selectedControllers[device.controllerId]) {
      selectedControllers[device.controllerId].push(...device.item.ports);
    } else {
      selectedControllers[device.controllerId] = [...device.item.ports];
    }
  });

  Object.entries(selectedControllers).forEach((controller) => {
    mqttControl.onPublishFirmware(controller[0], {
      serial: controller[0],
      cmd: 'so',
      type: 1,
      ports: controller[1],
    });
  });
};

const onBatchSettingClick = async () => {
  if (selectedDeviceIds.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '설정 장치를 선택해주세요.',
    });
    return;
  }

  try {
    await requestUpdateDevices({
      zoneId: zone.value._id,
      devices: selectedDeviceIds.value,
    });
    publishBatchOnOff();
    await store.dispatch('home/fetchAllZones');

    emitter.emit('showPopAlert', {
      description: '설정이 완료되었습니다.',
      okCallback: () => router.go(-1),
    });
  } catch (e) {
    emitter.emit('showPopAlert', {
      description: e,
    });
  }
};
</script>