<template>
  <!-- BEGIN login -->
  <div class="login">
    <!-- BEGIN login-content -->
    <div class="login-content">
      <form>
        <p class="display-1 text-center" style="font-weight: 900">INSHOW</p>
      </form>
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login -->
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  onUnmounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;
const isWebViewReady = computed(() => getters.value["home/isWebViewReady"]);
const controllerId = 'MC123456789012';
const eventId = 'onReceived';
let timerId = undefined;

const email = ref('');
const password = ref('');

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

const requestLogin = async (params) => {
  try {
    const result = await store.dispatch('login', params);
    if (result.resultCode === '0000') {
      clearTimeout(timerId);
      setTimeout(() => {
        router.push("/rooms");
      }, 2500);
      return;
    }
  } catch (error) {
  }
};

onBeforeMount(() => {
  const visibleSplash = sessionStorage.getItem('visible-splash');
  if (Boolean(visibleSplash)) {
    router.push('/login');
    return;
  }
  hideLayoutWidgets();
});

onMounted(() => {
  
  sessionStorage.setItem('visible-splash', true);  

  emitter.on('onGetLoginInfoResult', (payload) => {
    const { email: id, password: pwd, type } = payload?.data || {};
    if (id?.length > 0 && pwd?.length > 0) {
      email.value = id;
      password.value = pwd;

      requestLogin({
        type: type,
        email: email.value,
        password: password.value,
      });
    }    

    emitter.off('onGetLoginInfoResult');
  });

  let msec = 0;
  const interval = setInterval(() => {
    msec += 100;

    if (isWebViewReady.value) {
      mobile.getLoginInfo(JSON.stringify({ callback: 'onGetLoginInfoResult' }));
      clearInterval(interval);
    }

    if (msec >= 2900) {
      clearInterval(interval);
    }
  }, 100)

  timerId = setTimeout(() => {
    router.push('/login');
  }, 3000);
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

onUnmounted(() => {
  emitter.off(eventId);
});

</script>
