<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <Card
        :class="`mb-3 overflow-hidden fs-13px border-0`"
        style="min-height: 100px"
      >
      <CardBody class="position-relative">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="mb-1">SSID : {{ props.data.ssid }}</h5>            
          </div>                
        </div>
        <button
          type="button"        
          class="btn btn-primary"
          style="--bs-btn-padding-y: 0.16rem"
          @click="onConnectClick"
        >연결</button>
      </CardBody>
    </Card>
    </div>
  </div>
</template>

<script setup>
import { onMounted,defineProps, computed,getCurrentInstance } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps(['index','data']);
const store = useStore();
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});

onMounted(() =>{
  console.log('WifiItem.onMounted().props =>',props.data)  
})

const onConnectClick = () => {
  
  console.log('WifiItem.onConnectClick().props.data =>'+JSON.stringify(props.data)  )
  emit('onConnectClick',props.data);
};

const onDeleteClick = () =>{  
  // console.log('WifiItem.onDeleteClick =>',{name:props.data.name,zoneId:props.data._id})
  // emit('onDeleteClick',{name:props.data.name,zoneId:props.data._id})
}
</script>
