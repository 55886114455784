<template>
  <PopDialog
    title="닉네임 변경"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <input
      type="text"
      class="form-control form-control-lg fs-15px"
      placeholder="닉네임을 입력해주세요."
      v-model="modifiedNickname"
    />
  </PopDialog>
</template>

<script setup>
import { ref, getCurrentInstance, defineProps } from 'vue';
import Axios, { AuthApi } from '@/api';
import PopDialog from '@/common/components/popup/PopDialog.vue';

const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'nickName',
]);
let modifiedNickname = ref(props.nickName);

const onOkClick = () => {
  if (props.okCallback) {
    props.okCallback(modifiedNickname.value);
  }
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};
</script>
