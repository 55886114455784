const variables = {
  state: {
    font: {
      family: getComputedStyle(document.body)
        .getPropertyValue('--bs-body-font-family')
        .trim(),
      size: getComputedStyle(document.body)
        .getPropertyValue('--bs-body-font-size')
        .trim(),
      weight: getComputedStyle(document.body)
        .getPropertyValue('--bs-body-font-weight')
        .trim(),
    },
    color: {
      theme: getComputedStyle(document.body)
        .getPropertyValue('--app-theme')
        .trim(),
      componentColor: getComputedStyle(document.body)
        .getPropertyValue('--app-component-color')
        .trim(),
      componentBg: getComputedStyle(document.body)
        .getPropertyValue('--app-component-bg')
        .trim(),
      dark: getComputedStyle(document.body)
        .getPropertyValue('--bs-dark')
        .trim(),
      light: getComputedStyle(document.body)
        .getPropertyValue('--bs-light')
        .trim(),
      blue: getComputedStyle(document.body)
        .getPropertyValue('--bs-blue')
        .trim(),
      indigo: getComputedStyle(document.body)
        .getPropertyValue('--bs-indigo')
        .trim(),
      purple: getComputedStyle(document.body)
        .getPropertyValue('--bs-purple')
        .trim(),
      pink: getComputedStyle(document.body)
        .getPropertyValue('--bs-pink')
        .trim(),
      red: getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
      orange: getComputedStyle(document.body)
        .getPropertyValue('--bs-orange')
        .trim(),
      yellow: getComputedStyle(document.body)
        .getPropertyValue('--bs-yellow')
        .trim(),
      green: getComputedStyle(document.body)
        .getPropertyValue('--bs-green')
        .trim(),
      success: getComputedStyle(document.body)
        .getPropertyValue('--bs-success')
        .trim(),
      teal: getComputedStyle(document.body)
        .getPropertyValue('--bs-teal')
        .trim(),
      cyan: getComputedStyle(document.body)
        .getPropertyValue('--bs-cyan')
        .trim(),
      white: getComputedStyle(document.body)
        .getPropertyValue('--bs-white')
        .trim(),
      gray: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray')
        .trim(),
      lime: getComputedStyle(document.body)
        .getPropertyValue('--bs-lime')
        .trim(),
      gray100: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-100')
        .trim(),
      gray200: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-200')
        .trim(),
      gray300: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-300')
        .trim(),
      gray400: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-400')
        .trim(),
      gray500: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-500')
        .trim(),
      gray600: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-600')
        .trim(),
      gray700: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-700')
        .trim(),
      gray800: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-800')
        .trim(),
      gray900: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-900')
        .trim(),
      black: getComputedStyle(document.body)
        .getPropertyValue('--bs-black')
        .trim(),

      themeRgb: getComputedStyle(document.body)
        .getPropertyValue('--app-theme-rgb')
        .trim(),
      componentColorRgb: getComputedStyle(document.body)
        .getPropertyValue('--app-component-color-rgb')
        .trim(),
      componentBgRgb: getComputedStyle(document.body)
        .getPropertyValue('--app-component-bg-rgb')
        .trim(),
      darkRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-dark-rgb')
        .trim(),
      lightRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-light-rgb')
        .trim(),
      blueRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-blue-rgb')
        .trim(),
      indigoRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-indigo-rgb')
        .trim(),
      purpleRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-purple-rgb')
        .trim(),
      pinkRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-pink-rgb')
        .trim(),
      redRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-red-rgb')
        .trim(),
      orangeRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-orange-rgb')
        .trim(),
      yellowRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-yellow-rgb')
        .trim(),
      greenRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-green-rgb')
        .trim(),
      successRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-success-rgb')
        .trim(),
      tealRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-teal-rgb')
        .trim(),
      cyanRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-cyan-rgb')
        .trim(),
      whiteRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-white-rgb')
        .trim(),
      grayRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-rgb')
        .trim(),
      limeRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-lime-rgb')
        .trim(),
      gray100Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-100-rgb')
        .trim(),
      gray200Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-200-rgb')
        .trim(),
      gray300Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-300-rgb')
        .trim(),
      gray400Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-400-rgb')
        .trim(),
      gray500Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-500-rgb')
        .trim(),
      gray600Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-600-rgb')
        .trim(),
      gray700Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-700-rgb')
        .trim(),
      gray800Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-800-rgb')
        .trim(),
      gray900Rgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-gray-900-rgb')
        .trim(),
      blackRgb: getComputedStyle(document.body)
        .getPropertyValue('--bs-black-rgb')
        .trim(),
    },
  },
  getters: {
    font: (state) => state.font,
    color: (state) => state.color,
  },
  mutations: {
    setColorTheme: (state, payload) => (state.color.theme = payload),
    setColorThemeRgb: (state, payload) => (state.color.themeRgb = payload),
  },
  actions: {},
};

export default variables;
