import { getCurrentInstance ,reactive} from 'vue';
import { useStore } from "vuex";
import { useMQTT } from 'mqtt-vue-hook'
import { MQTTCommand } from '../constants';


export default function useMqttControl() {  
   
  const store = useStore();
  const mqttHook = useMQTT()
  const { mqtt,mobile ,logger} = getCurrentInstance().appContext.config.globalProperties;

  const subscribe = async (topics) =>{
    await mqtt.subscribe(topics);   

    // topics.forEach(topic => {          
    for (const topic of topics){
      await mqttHook.unRegisterEvent(topic)
      await mqttHook.registerEvent(
        topic,
        (topic,message) =>{
          console.log(`[mqtt] subscribe received => topic : ${topic},message : ${message.toString()}`)
          if(topic.includes('mcs')){
            let data = JSON.parse(message.toString())
            // console.log(`data : ${message.toString()}`)
            switch(data.cmd){
              case MQTTCommand.SettingMode:
                store.dispatch("home/changeDeviceSettingMode",data)
                break;
              case MQTTCommand.StateChanged:
              case MQTTCommand.Control:
                store.dispatch("home/chanageDeviceState",data)
                break;
              case MQTTCommand.FirmwareUpdateStarted:
              case MQTTCommand.FirmwareUpdateFinished:
                store.dispatch("home/changeControllerFirmwareUpdateState",data)
                break;         
              case MQTTCommand.Version:
                store.dispatch("home/updateControllerFirmwareVersion",data)
                break;     
              case MQTTCommand.Ping:
                store.dispatch("home/updateCheckControllers", { ...data, value: 1 })
                break;
            }
            // if(data.cmd === MQTTCommand.SettingMode){            
            //   store.dispatch("home/addSettingModeDevice",data)
            // }else if(data.cmd === MQTTCommand.StateChanged){
            //   store.dispatch("home/chanageDeviceState",data)
            // }
          }else if(topic.includes('zone')){
            const arrTopics = topic.split('/')
            const zoneId = arrTopics[3]
            const payload = JSON.parse(message.toString())
            console.log(`[mqtt] subscribe received => zoneId : ${zoneId},payload : ${message.toString()}`)

            const zone = store.getters['home/getZones'].find(z => z._id === parseInt(zoneId))
            if(zone){
              console.log(`[mqtt] subscribe received => zone : ${zone.name},payload : ${message.toString()}`)
              if(payload.isEditMode){
                mobile.showSnackBar(`영역[${zone.name}]이 설정 상태로 변경되었습니다.`)
              }else{
                mobile.showSnackBar(`영역[${zone.name}]이 설정이 완료되었습니다.`)
              }
            }            

            store.dispatch("home/changeZoneEditMode",{...payload,zoneId:parseInt(zoneId)})             
          }
        }
      )  
    };

    return;
  }
  const unSubscribe = async (topics) =>{
    // console.log('useMqttControl.unSubscribe => topics : ',topics)
    // topics.forEach(async (topic) => {
    //   await mqtt.unSubscribe(topic)
    // })    

    console.log('unSubscribe => topics : ',topics)
    for(const topic of topics) {
      await mqtt.unSubscribe(topic)
    }
    return;
  }
  
  const onPublish = ((controllerId,deviceId,payload) =>{              
    console.log(`useMqttControl.onPublish => controllerId : ${controllerId},deviceId : ${deviceId},payload : ${payload}`)
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
    store.dispatch("home/updateGroupDeviceState",{...payload,deviceId:deviceId}) 
  })
  const onPublishZone = ((zoneId,payload) =>{              
    console.log(`useMqttControl.onPublishZone => zoneId : ${zoneId},payload : ${JSON.stringify(payload)}`)
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_ZONE_TOPIC_PREFIX}/${zoneId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
    // store.dispatch("home/updateGroupDeviceState",{...payload,deviceId:deviceId}) 
  })

  const onPublishFirmware = (controllerId, payload)=> {    
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
  }

  const onPublishFade = (controllerId, payload)=> {    
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
  }

  const registerEvent = (eventName,callback) =>{        
    mqttHook.registerEvent(eventName,callback)
  }

  const unRegisterEvent = (eventName) =>{
    mqttHook.unRegisterEvent(eventName)
  }

  const isConnected = () => {
    return mqttHook.isConnected()
  }
  const clearEvent = async() => {
    return await mqttHook.clearEvent()
  }
  return {subscribe,unSubscribe,onPublish,onPublishZone,onPublishFirmware,onPublishFade,registerEvent,unRegisterEvent,isConnected,clearEvent}  
}
