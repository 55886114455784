<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">프로필</h4>
    <!-- <p>
      List widget is created by using existing Bootstrap
      <code>.list-group</code> component with <code>.d-flex</code> and hud
      utilities css classes.
    </p> -->
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>개인정보</b></div>
        <div class="list-group mb-3">
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;">
            <div class="flex-fill">
              <div class="fw-600">{{ profile?.shareCode || '' }}</div>
              <div class="fs-12px text-muted">공유 코드</div>
            </div>
            <div
              style="width: 15px; height: 15px"
              @click.prevent="onCopyShareCodeClick"
            >
              <input
                ref="clipboardElem"
                type="hidden"
                :value="profile?.shareCode || ''"
              />
              <a class="text-muted"><i class="fa-solid fa-clipboard"></i></a>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" v-if="profile?.email">
            <div class="flex-fill">
              <div class="fw-600">{{ profile?.email || '' }}</div>
              <div class="fs-12px text-muted">이메일</div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem;">
            <div class="flex-fill">
              <div class="fw-600">{{ profile?.nickName || '' }}</div>
              <div class="fs-12px text-muted">닉네임</div>
            </div>
            <div style="width: 15px; height: 15px" @click="onEditNicknameClick">
              <a class="text-muted"
                ><i class="fa-solid fa-pen-to-square"></i
              ></a>
            </div>
          </ListGroupItem>

          <hr class="mt-4" v-if="getters.isLogin" />
          
          <button class="btn btn-default mt-2" v-if="getters.isLogin" @click="onDeleteMemberClick">회원탈퇴</button>
        </div>
      </div>
    </div>
    <NicknameModal
      v-if="nicknameModal.visible"
      :visible="nicknameModal.visible"
      :okCallback="onUpdateNicknameClick"
      :cancelCallback="nicknameModal.hide"
      :nickName="profile.nickName"
    />
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Axios, { AuthApi } from '@/api';
import NicknameModal from './components/NicknameModal.vue';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const getters = computed(() => store.getters);
const profile = computed(() => getters.value.account);
const router = useRouter();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const nicknameModal = reactive(useModal());
const clipboardElem = ref(null);

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting', { title: '프로필', isShowZone: false });
});
onBeforeUnmount(() => {
  emitter.emit('onChangeHeaderSetting', { title: '', isShowZone: true });
});

const onCopyShareCodeClick = () => {
  clipboardElem.value.setAttribute('type', 'text');
  clipboardElem.value.select();
  document.execCommand('copy');
  clipboardElem.value.setAttribute('type', 'hidden');

  emitter.emit('showPopToast', {
    description: '공유코드가 클립보드에 저장되었습니다.',
    type: 'success',
  });
};

const onEditNicknameClick = () => {
  nicknameModal.show();
};

const onUpdateNicknameClick = (value) => {
  requestUpdateAccount({
    nickName: value,
  });
  nicknameModal.hide();
};

const onDeleteMemberClick = () => {
  router.push("/delete/user");
};

const requestUpdateAccount = async (params) => {
  try {
    const result = await Axios.post(AuthApi.UPDATE_ACCOUNT, params, 'auth');
    if (result.resultCode === '0000') {
      if (result.resultData.nickName) {
        store.commit('setNickname', result.resultData.nickName);
      }
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '프로필 수정에 실패했습니다.',
    });
  }
};
</script>
