<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">

        <h2 class="title mt-4">{{device.name}}</h2>
        <!-- <div class="mb-3" style="font-size: 0.8rem">
          응답 없음
        </div> -->
        <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${getPower ? '#ffe082' : 'inherits'}`"
          @click="props.handlePowerClick"
        >
          <i class="fa-solid fa-power-off"></i>
        </button>

        <div style="margin-right: 20px;">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              :name="
                device?.item?.temperature
                ? `온도 ${device.item.temperature}&#176;c`
                : '온도'
              "
              id="temperature"
              :default="boilerTemp"
              :nameWidth="3"
              :min="10"
              :max="40"
              v-on:onSliderChanged="onTemperatureChanged"
            />
          </ListGroupItem>
        </div>
      </div>
    </div>
  </PopDialog2>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const props = defineProps([
  'visible',
  'cancelCallback',
  'roomName',
  'roomId',
  'deviceId',
  'handlePowerClick'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const device = computed(() => {
  return devices.value.find(
    (d) => d._id === props.deviceId,
  );
});

const getPower = computed(() => {
  return device.value.item.onoff === PowerState.On;
});

const boilerTemp = computed(() => {
  return device.value.item.temperature;
});

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onTemperatureChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: device.value.type,
    data: {
      serial: device.value.item.serial,
      onoff: device.value.item.onoff,
      temperature: parseInt(e),
    },
  });
};
</script>
