import { createStore } from 'vuex';
import auth from './auth';
import options from './options';
import sidebar from './sidebar';
import variables from './variables';
import home from './home';
import popup from './popup';
import toast from './toast';

export default createStore({
  modules: { auth, options, sidebar, variables, home, popup, toast },
});
