<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-fill">
          <div class="fw-600" style="font-size: 1rem">
            <i class="fa-solid fa-lightbulb" style="margin-right: 0.5rem"></i
            >{{ props.name
            }}<i
              class="fa-solid fa-pen-to-square ms-3 text-primary"
              style="cursor: pointer"
              @click="onEditDeviceClick"
              v-if="zone?.isOwner"
            ></i>
          </div>
        </div>
        <div>
          <OnOffButton
            :value="getPower"
            name="light-item"
            :index="props.deviceId"
            @change="onPowerClicked"
          />
        </div>
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
      >
        <Slider
          name="밝기"
          id="color-bright"
          :min="10"
          :max="100"
          :step="10"
          :default="getBright"
          v-on:onSliderChanged="onBrightChanged"
        />
      </ListGroupItem>
      <ListGroupItem
        style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
      >
        <Slider
          name="색온도"
          id="color-temp"
          :min="0"
          :max="20"
          :step="2"
          :default="getColor"
          v-on:onSliderChanged="onColorChanged"
        />
      </ListGroupItem>
    </div>
    <DeviceItemModal
      v-if="deviceItemModal.visible"
      :visible="deviceItemModal.visible"
      :okCallback="onUpdateDeviceName"
      :cancelCallback="deviceItemModal.hide"
      :deviceName="props.name"
      :groupId="props.groupId"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  reactive,
  computed,
  getCurrentInstance,
  onMounted,
} from 'vue';

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import useMqttControl from '@/common/hooks/useMqttControl';
import DeviceItemModal from './DeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { DeviceApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());

const { emit } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const store = useStore();
const props = defineProps(['name', 'groupId', 'deviceId']);
const deviceItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const device = computed(() => {
  return store.getters['home/getCurrentGroup'].devices.find(
    (d) => d._id === props.deviceId,
  );
});
const getBright = computed(() => {
  return device.value.item.bright;
});
const getColor = computed(() => {
  return device.value.item.color;
});
const getPower = computed(() => {
  return device.value.item.onoff === 1;
});
const onPowerClicked = (e) => {
  emit('onPowerClick', { onoff: getPower.value ? 0 : 1 });
};

const onBrightChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: device.value.item.ports.map((p) => p),
      onoff: device.value.item.onoff,
      bright: parseInt(e),
      color: device.value.item.color,
    },
  });
};

const onColorChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: device.value.item.ports.map((p) => p),
      onoff: device.value.item.onoff,
      bright: device.value.item.bright,
      color: parseInt(e),
    },
  });
};

const onEditDeviceClick = () => {
  deviceItemModal.show();
};

const onUpdateDeviceName = (value) => {
  requestUpdateDevice({
    name: value,
  });
  deviceItemModal.hide();
};

const requestUpdateDevice = async (params) => {
  try {
    const result = await Axios.patch(
      DeviceApi.UPDATE_DEVICE.replace(':id', props.deviceId),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateDevice', {
        groupId: props.groupId,
        id: props.deviceId,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '장치 정보 수정에 실패했습니다.',
    });
  }
};

// const onLightItemClick = () => {
//   store.commit("home/setCurrentDevice",props.deviceId )
//   router.push(`/rooms/${route.params.roomId}/device/${props.deviceId}`);
// };
</script>
