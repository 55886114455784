<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">{{ props.step }}. Wifi 설정</h4>
      <p class="mb-0">
        1. 연결된 컨트롤러에 현재 위치의 Wifi
        <code style="font-size: 100%">SSID</code>와
        <code style="font-size: 100%">비밀번호</code>를 입력해주세요.
      </p>
      <p class="mb-0">
        2. 아래의
        <code style="font-size: 100%">등록 버튼</code>을 눌러 연결된 컨트롤러에
        입력된 Wifi 정보를 설정해주세요. Wifi 정보가 다르면 제대로 동작하지
        않습니다.
      </p>
    </div>

    <hr class="mb-3" />

    <div class="col-xl-6 mb-3">
      <div class="text-muted fs-12px mb-2"><b>컨트롤러</b></div>
      <div class="list-group" style="border-radius: 1.2rem">
        <ListGroupItem
          style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
        >
          <div class="flex-1 text-break">
            <div style="font-size: 1rem">{{ props.controller.model }}</div>
          </div>
        </ListGroupItem>

        <ListGroupItem
          class="text-gray-800"
          style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
        >
          시리얼 넘버 : {{ props.controller._id }}
        </ListGroupItem>

        <ListGroupItem
          class="text-gray-800"
          style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
        >
          펌웨어 버전 :
          {{ props.controller.version }}
        </ListGroupItem>
      </div>
    </div>

    <div class="col-xl-6 mb-3">
      <div class="text-muted fs-12px mb-2"><b>Wifi</b></div>
      <div class="list-group" style="border-radius: 1.2rem">
        <div class="list-group-item" style="padding-top: 0.2rem; padding-bottom: 0.2rem;">
          <div>
            <label class="form-label mb-1 fw-normal">SSID</label>
          </div>
          <input
            type="text"
            class="form-control form-control-lg fs-15px mb-1 col"
            placeholder="SSID를 입력해주세요."
            :value="ssid"
            @change="onSsidChange"
          />
        </div>
        <div class="list-group-item" style="border-top-width: 1px; padding-top: 0.3rem; padding-bottom: 0.4rem;">
          <div>
            <label class="form-label mb-1 fw-normal">비밀번호 입력</label>
          </div>
          <input
            type="text"
            class="form-control form-control-lg fs-15px col"
            placeholder="비밀번호를 입력해주세요."
            :value="password"
            @change="onPasswordChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { logger } = getCurrentInstance().appContext.config.globalProperties;
const props = defineProps([
  'controller',
  'ssid',
  'password',
  'step',
  'handleSsidChange',
  'handlePasswordChange',
]);
const ssid = ref(props.ssid);
const password = ref(props.password);

const onSsidChange = (e) => {
  ssid.value = e.target.value;
  if (props.handleSsidChange) {
    props.handleSsidChange(e.target.value);
  }
};

const onPasswordChange = (e) => {
  password.value = e.target.value;
  if (props.handlePasswordChange) {
    props.handlePasswordChange(e.target.value);
  }
};
</script>
