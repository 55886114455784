<template>
  <div class="login">
    <div class="login-content" v-if="certStatus == 1">
      <h1 class="text-center">회원가입 인증 중</h1>
      <div class="text-muted text-center mb-4">
        이메일 인증을 진행하고 있습니다.
      </div>
    </div>

    <div class="login-content" v-else-if="certStatus == 2">
      <h1 class="text-center">회원가입 인증 완료</h1>
      <div class="text-muted text-center mb-4">
        회원가입이 완료되었습니다. 앱에서 로그인 해주세요.
      </div>
    </div>

    <div class="login-content" v-else-if="certStatus == 3">
      <h1 class="text-center">회원가입 인증 완료</h1>
      <div class="text-muted text-center mb-4">
        이미 인증되었습니다. 앱에서 로그인 해주세요.
      </div>
    </div>

    <div class="login-content" v-else-if="certStatus == 4">
      <h1 class="text-center">인증메일 재전송</h1>
      <div class="text-muted text-center mb-4">
        인증메일이 재전송 되었습니다. 이메일 인증을 다시 시도해주세요.
      </div>
    </div>

    <div class="login-content" v-else-if="certStatus == 5">
      <h1 class="text-center">인증메일 재전송 중</h1>
      <div class="text-muted text-center mb-4">
        인증메일을 재전송 하고 있습니다.
      </div>
    </div>

    <div class="login-content" v-else>
      <h1 class="text-center">회원가입 인증 실패</h1>
      <div class="text-muted text-center mb-4">
        이메일 인증이 실패했습니다. 이메일 인증을 다시 시도해주세요.
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-lg d-block w-100 fw-500 mb-4"
        @click="onResendEmailClick"
      >
        다시 보내기
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  onBeforeMount,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useMQTT } from 'mqtt-vue-hook';
import { kakaoAuthUrl } from '@/common/auth/kakao';
import { googleAuthUrl } from '@/common/auth/google';
import { checkValidEmail } from '@/common/utils/validUtil';
import Axios, { AuthApi } from '@/api';

const store = useStore();
const getters = computed(() => store.getters);
const router = useRouter();
const route = useRoute();
const certStatus = ref(1);
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

const requestCertification = (params) => {
  Axios.patch(AuthApi.CERTIFICATION, params)
    .then((res) => {
      console.log(res);
      if (res.resultCode == "0000") {
        certStatus.value = 2;
      } else if (res.resultCode == "7002") {
        certStatus.value = 3;
      } else {
        console.error(res.resultMessage);
        certStatus.value = 100;
      }
    })
    .catch((err) => {
      console.error(err);
        certStatus.value = 100;
    });
};

onBeforeMount(() => {
  if (!route.query?.certKey || !route.query?.id) {
    router.push("/login");
    return;
  }
  hideLayoutWidgets();
});

onBeforeUnmount(() => {
  store.dispatch('logout');
  showLayoutWidgets();
});

onMounted(() => {
  requestCertification({ 
    certKey: route.query?.certKey,
    id: route.query?.id
  });
});

const onResendEmailClick = () => {
  certStatus.value = 5;

  Axios.get(AuthApi.SEND_EMAIL, {
    id: route.query?.id
  })
    .then((res) => {
      console.log(res);
      if (res.resultCode == "0000") {
        certStatus.value = 4;
      } else {
        emitter.emit('showPopAlert', {
          description: res.resultMessage,
        });
        certStatus.value = 100;
      }
    })
    .catch((err) => {
      console.error(err);
      emitter.emit('showPopAlert', {
        description: "인증 메일 재전송에 실패했습니다.",
      });
      certStatus.value = 100;
    });
};
</script>
