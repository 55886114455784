<template>
  <div
    class="modal fade show"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    style="display: block; background-color: transparent"
  >
    <div
      class="spinner-border text-primary"
      style="
        display: block;
        position: fixed;
        top: calc(50% - (32px / 2));
        right: calc(50% - (32px / 2));
      "
    ></div>
  </div>
</template>
