<template>
  <div
    v-if="getters.visible"
    class="modal fade show"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    style="display: block; background-color: rgba(0, 0, 0, 0.3)"
    @click.stop="onBackClick"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-sm"
      @click.stop="() => {}"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{ getters.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click.stop="onCloseClick"
          ></button>
        </div>

        <div class="modal-body">
          <div v-if="getters.slot"><slot></slot></div>
          <div v-else v-for="(item, index) in descriptions" :key="index">
            {{ item }}
          </div>
        </div>

        <div class="modal-footer">
          <button
            v-if="getters.showCancel"
            type="button"
            class="btn btn-secondary"
            @click.stop="onCancelClick"
          >
            {{ getters.cancelText }}
          </button>
          <button type="button" class="btn btn-primary" @click.stop="onOkClick">
            {{ getters.okText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const getters = computed(() => store.getters);
const descriptions = computed(() =>
  store.getters.description
    ?.split('\n')
    .filter((item) => item.length > 0)
    .map((item) => `${item}`),
);

const onOkClick = () => {
  if (getters.value.okCallback) {
    getters.value.okCallback();
  }
  store.dispatch('hide');
};

const onCancelClick = () => {
  if (getters.value.cancelCallback) {
    getters.value.cancelCallback();
  }
  store.dispatch('hide');
};

const onCloseClick = () => {
  if (getters.value.closeCallback) {
    getters.value.closeCallback();
  } else if (getters.value.cancelCallback) {
    getters.value.cancelCallback();
  }
  //  else if (!getters.value.showCancel && getters.value.okCallback) {
  //   getters.value.okCallback();
  // }
  store.dispatch('hide');
};

const onBackClick = () => {
  if (getters.value.clickable) {
    onCloseClick();
  }
};
</script>
