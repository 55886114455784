<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">

        <h2 class="title mt-4">
          {{props.roomName}} 조명
        </h2>
        <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${deviceState(1) ? '#ffe082' : 'inherits'}`"
          @click="props.handlePowerClick"
        >
          <i class="fa-solid fa-power-off"></i>
        </button>

        <div style="margin-right: 20px;">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              name="밝기"
              id="color-bright"
              :min="10"
              :max="100"
              :step="10"
              :default="lightBright"
              v-on:onSliderChanged="onBrightChanged"
            />
          </ListGroupItem>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              name="색온도"
              id="color-temp"
              :min="0"
              :max="20"
              :step="2"
              :default="lightColor"
              v-on:onSliderChanged="onColorChanged"
            />
          </ListGroupItem>
        </div>

        <div style="margin-left: 20px;margin-right: 20px; margin-top: 10px;">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <button
              type="button"
              class="btn btn-primary flex-fill"
              @click="onAddVirtualDeviceClick"
            >
              가상버튼 추가
            </button>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </PopDialog2>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeviceType, PowerState } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const router = useRouter();
const props = defineProps([
  'visible',
  'cancelCallback',
  'roomName',
  'roomId',
  'handlePowerClick'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});

const lightDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 1,
  );
});
const lightBright = computed(() => {
  if (lightDevices.value.length === 0) {
    return 0;
  }

  let bright = 0;
  lightDevices.value.forEach((device) => {
    if (device.item?.bright > bright && device.item?.onoff) {
      bright = device.item?.bright;
    }
  });
  return bright;
});
const lightColor = computed(() => {
  if (lightDevices.value.length === 0) {
    return 0;
  }

  let color = 0;
  lightDevices.value.forEach((device) => {
    if (device.item?.color > color && device.item?.onoff) {
      color = device.item?.color;
    }
  });
  return color;
});

const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.onoff === PowerState.On);
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onBrightChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  lightDevices.value.filter(device => !device.isVirtual).forEach((device) => {
    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: 1,
      data: {
        ports: device.item.ports.map((p) => p),
        onoff: device.item.onoff,
        bright: parseInt(e),
        color: device.item.color,
      },
    });
  })
};

const onColorChanged = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  
  lightDevices.value.filter(device => !device.isVirtual).forEach((device) => {
    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: 1,
      data: {
        ports: device.item.ports.map((p) => p),
        onoff: device.item.onoff,
        bright: device.item.bright,
        color: parseInt(e),
      },
    });
  })
};

const onAddVirtualDeviceClick = () => {
  router.push(`/virtual/${props.roomId}`);
}
</script>