<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">이관하기</h4>
      <p class="mb-0">
        1. 이관 받을 사용자의
        <code style="font-size: 100%">공유코드</code>를 입력해주세요.
      </p>
      <p class="mb-0">
        2. 이관할 영역을
        <code style="font-size: 100%">체크박스</code>에 체크해주세요.
      </p>
      <p class="mb-0">
        3. 아래의
        <code style="font-size: 100%">이관 버튼</code>을 눌러서 다른 사용자에게
        설정 정보를 이관해주세요. 이관 받은 사용자는 필요에 따라
        <code style="font-size: 100%">와이파이 변경</code>이 필요할 수 있습니다.
      </p>
    </div>

    <hr class="mb-3" />

    <div class="row">
      <div class="col-xl-6 mb-3">
        <div class="text-muted fs-12px mb-2"><b>공유코드 입력</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <div class="list-group-item" style="border-radius: 1.2rem;">
            <input
              type="text"
              class="form-control form-control-lg fs-15px mb-1 col"
              placeholder="설정 정보를 받을 사용자 공유코드를 입력해주세요."
              v-model="sharedCode"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 mb-0">
        <div class="text-muted fs-12px mb-2"><b>이관 영역 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(zone, index) in zones"
            :key="zone._id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="zone._id"
                @change="onSelectZoneChange"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ zone.name }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onTransferClick"
          >
            이관
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeviceDefault } from '@/common/constants';
import Axios, { CtrlApi, ZoneApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const router = useRouter();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;

const sharedCode = ref('');
const selectedZones = ref([]);
const account = computed(() => store.getters['account']);
const zones = computed(() => store.getters['home/getZones']);

const onSelectZoneChange = (e) => {
  const targetIndex = selectedZones.value.findIndex(
    (item) => item == e.target.value,
  );
  if (targetIndex >= 0) {
    selectedZones.value = selectedZones.value.filter(
      (item) => item != e.target.value,
    );
  } else {
    selectedZones.value.push(e.target.value);
  }
};

const requestTransferSend = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      // logger.log(
      //   `TransferSendPage.requestTransferSend => params : ${JSON.stringify(
      //     params,
      //   )}`,
      // );

      const result = await Axios.patch(CtrlApi.TRANSFER_SEND_CTRL, params);
      if (result.resultCode === '0000') {
        // logger.log(
        //   `TransferSendPage.requestTransferSend => result : ${JSON.stringify(
        //     result,
        //   )}`,
        // );
        resolve(result);
        return;
      }

      reject(result.resultMessage);
    } catch (e) {
      console.log(e);
      reject('컨트롤러 이관 중 오류가 발생하였습니다.');
    }
  });
};

const onTransferClick = async () => {
  if (sharedCode.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '공유코드를 입력해주세요.',
    });
    return;
  }
  if (selectedZones.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '이관 영역을 선택해주세요.',
    });
    return;
  }

  try {
    await requestTransferSend({
      userId: account.value._id,
      sharedCode: sharedCode.value,
      zones: selectedZones.value,
    });
    await store.dispatch('home/fetchAllZones');

    emitter.emit('showPopAlert', {
      description: '이관이 완료되었습니다.',
      okCallback: () => router.go(-1),
    });
  } catch (e) {
    emitter.emit('showPopAlert', {
      description: e,
    });
  }
};
</script>
