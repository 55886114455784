<template>
  <div>정보</div>
</template>

<script setup>
import { computed, getCurrentInstance ,onBeforeMount, onBeforeUnmount} from 'vue';
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

onBeforeMount(() => {  
  emitter.emit('onChangeHeaderSetting',{title:'앱 정보',isShowZone:false});  
});
onBeforeUnmount(() =>{
  emitter.emit('onChangeHeaderSetting',{title:'',isShowZone:true});
})
</script>
